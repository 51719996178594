import React, { useState, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Flex,
  Icon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { ImCheckmark } from "react-icons/im";

const TransferRequest = ({ isOpen, onClose, username }) => {
  const [paypalEmail, setPaypalEmail] = useState("");
  const [transferRequested, setTransferRequested] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handlePaypalEmailChange = (e) => {
    setPaypalEmail(e.target.value);
  };

  const handleTransfer = useCallback((e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const form = e.target;
    const formData = new FormData(form);
    formData.append("Username", username);
    fetch("https://script.google.com/macros/s/AKfycbwZT_Kf0dsXd-DLy6HNrLwmSCcr7s3VPRxstuh5B_lzKxF8w1IK6Q1RDVRq6hrRtmm3eQ/exec", {
      method: "POST",
      body: formData
    })
    .then(response => {
      if (response.ok) {
        setTransferRequested(true);
      } else {
        console.error("Form submission failed");
      }
    })
    .catch(error => {
      console.error("Error:", error);
    })
    .finally(() => {
      setIsSubmitting(false);
    });
  }, [username]);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(paypalEmail));
  }, [paypalEmail]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={isTabletOrMobile ? "sm" : "md"}>
      <ModalOverlay />
      <ModalContent 
        py={isTabletOrMobile ? "1rem" : "2rem"} 
        px={isTabletOrMobile ? "1rem" : "2rem"} 
        borderRadius="none" 
        border="3px solid black" 
        backgroundColor="base.100"
        maxW={isTabletOrMobile ? "90%" : "500px"}
      >
        <ModalCloseButton position="absolute" left={2} color="black" />
        <VStack spacing={isTabletOrMobile ? "0.5rem" : "1rem"} align="stretch">
          <Text fontSize={isTabletOrMobile ? "lg" : "2xl"} fontWeight="bold" mt={isTabletOrMobile ? "0" : "-1rem"} textAlign="center">
            Transfer Request
          </Text>
          <ModalBody>
            <form onSubmit={handleTransfer}>
              <VStack spacing={isTabletOrMobile ? "0.5rem" : "1rem"} align="stretch">
                <FormControl>
                  {!transferRequested && (
                    <FormLabel fontSize={isTabletOrMobile ? "sm" : "md"} textAlign="center">
                      Please enter your PayPal email.
                    </FormLabel>
                  )}
                  {transferRequested && (
                    <FormLabel fontSize={isTabletOrMobile ? "sm" : "md"} textAlign="center">
                      We will get back to you in 24-48 hours!
                    </FormLabel>
                  )}
                  <Input
                    type="email"
                    name="Paypal"
                    value={paypalEmail}
                    onChange={handlePaypalEmailChange}
                    placeholder="Enter your PayPal email"
                    size={isTabletOrMobile ? "sm" : "md"}
                    fontSize={isTabletOrMobile ? "xs" : "sm"}
                    width="100%"
                    required
                    disabled={transferRequested || isSubmitting}
                  />
                  <Input
                    type="hidden"
                    name="Username"
                    value={username}
                  />
                </FormControl>
              </VStack>
              <ModalFooter>
                <Flex justify="center" width="100%">
                  {transferRequested ? (
                    <VStack alignItems="stretch" justifyItems="stretch" width="100%">
                      <Button 
                        className="request-transfer-button-requested"
                        colorScheme="green"
                        size={isTabletOrMobile ? "sm" : "md"}
                        width="100%"
                        disabled
                      >
                        Transfer Requested!
                        <Icon as={ImCheckmark} color="white" fill="white" fontSize="xl" ml=".5rem" />
                      </Button>
                    </VStack>
                  ) : (
                    <Box width="100%">
                      <Button 
                        className="request-transfer-button"
                        type="submit"
                        colorScheme="green"
                        size={isTabletOrMobile ? "sm" : "md"}
                        width="100%"
                        isDisabled={!isValidEmail || isSubmitting}
                        _hover={!isValidEmail || isSubmitting ? {} : undefined}
                      >
                        {isSubmitting ? <Spinner size="sm" /> : "Submit"}
                      </Button>
                    </Box>
                  )}
                </Flex>
              </ModalFooter>
            </form>
          </ModalBody>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default TransferRequest;
