import React from "react";
import { Box, Icon, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useMediaQuery } from "react-responsive";

export default function BurgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const loc = useLocation();
  const { influencer, logout } = useAuth();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleLogout = async () => {
    await logout();
    navigate("/influencer.html");
  };

  const handleShowInfluencerProfile = () => {
    if (influencer && loc.pathname !== `/influencer/${influencer.username}`) {
      navigate(`/influencer/${influencer.username}`);
    }
  };

  const handleShowInfluencerDashboard = () => {
    if (loc.pathname !== `/influencer/dashboard`) {
      navigate(`/influencer/dashboard`);
    }
  };

  const handleShowManagedVideos = () => {
    if (loc.pathname !== `/influencer/manage-videos`) {
      navigate(`/influencer/manage-videos`);
    }
  };

  return (
    // Only showing burger menu if influencer is logged in.
    influencer &&
    influencer?.username && (
      <Menu minW={"100%"} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <MenuButton cursor={"pointer"} position={isTabletOrMobile ? "static" : "absolute"} top={isTabletOrMobile ? "auto" : "1rem"} right={isTabletOrMobile ? "auto" : "1rem"}>
          <Box
            borderRadius={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            h={"2.5rem"}
            w={"2.5rem"}
            cursor={"pointer"}
          >
            <Icon w={8} h={8} as={RxHamburgerMenu}></Icon>
          </Box>
        </MenuButton>
        <MenuList>
          <>
            <MenuItem onClick={(e) => handleShowInfluencerProfile()} borderBottom={"1px solid black"}>
              {"My Profile"}
            </MenuItem>
            <MenuItem onClick={(e) => handleShowInfluencerDashboard()} borderBottom={"1px solid black"}>
              {"My Dashboard"}
            </MenuItem>
            <MenuItem onClick={(e) => handleShowManagedVideos()} borderBottom={"1px solid black"}>
              {"Add or Remove Videos"}
            </MenuItem>
            <MenuItem onClick={handleLogout}>{"Logout"}</MenuItem>
          </>
        </MenuList>
      </Menu>
    )
  );
}

