import React from "react";
import { Box, Menu, MenuButton, MenuItem, MenuList, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAuth } from "../../hooks/useAuth";

function LoggedIn(props) {
  const navigate = useNavigate();
  const loc = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { user, influencer, logout } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderUserIcon = () => {
    if (user && user.firstName) {
      let first = user && user.firstName ? user.firstName[0] : "U";
      let last = user && user.lastName ? user.lastName[0] : "N";
      let icon = first + last;
      return (
        <Text size={"xl"} fontWeight={"700"} color={"white"}>
          {icon}
        </Text>
      );
    } else if (influencer && influencer.profileImage) {
      return <img src={influencer.profileImage} style={{ borderRadius: "50%" }} alt="" />;
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleShowInfluencerProfile = () => {
    if (loc.pathname !== `/influencer/${influencer.username}`) {
      navigate(`/influencer/${influencer.username}`);
    }
  };

  const handleShowInfluencerDashboard = () => {
    if (loc.pathname !== `/influencer/dashboard`) {
      navigate(`/influencer/dashboard`);
    }
  };

  const handleShowManagedVideos = () => {
    if (loc.pathname !== `/influencer/manage-videos`) {
      navigate(`/influencer/manage-videos`);
    }
  };

  return (
    <VStack display={props.display ? props.display : "auto"}>
      {isTabletOrMobile ? (
        <Box
          borderRadius={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={"#f39d20"}
          h={"3rem"}
          w={"3rem"}
          cursor={"pointer"}
          onClick={(e) => handleShowInfluencerDashboard()}
        >
          {renderUserIcon()}
        </Box>
      ) : (
        // wrapped in Box component only to remove warning, is a bug in chakra-ui
        // https://github.com/chakra-ui/chakra-ui/issues/3440#issuecomment-851707911
        <Box>
          <Menu minW={"100%"} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <MenuButton cursor={"pointer"}>
              <Box
                borderRadius={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#f39d20"}
                h={"2.5rem"}
                w={"2.5rem"}
                cursor={"pointer"}
              >
                {renderUserIcon()}
              </Box>
            </MenuButton>
            <MenuList>
              {influencer && influencer?.username && (
                <>
                  <MenuItem onClick={(e) => handleShowInfluencerProfile()} borderBottom={"1px solid black"}>
                    {"My Profile"}
                  </MenuItem>
                  <MenuItem onClick={(e) => handleShowInfluencerDashboard()} borderBottom={"1px solid black"}>
                    {"My Dashboard"}
                  </MenuItem>
                  <MenuItem onClick={(e) => handleShowManagedVideos()} borderBottom={"1px solid black"}>
                    {"Add or Remove Videos"}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>{"Logout"}</MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </Box>
      )}
    </VStack>
  );
}

export default LoggedIn;

