import React, { createContext, useState, useContext } from "react";

const AbortControllerContext = createContext();

export const AbortControllerProvider = ({ children }) => {
  const [controllers, setControllers] = useState({
    itinerary: null,
    chat: null,
    reccs: null,
  });

  const createController = (key) => {
    const newController = new AbortController();
    setControllers((prevControllers) => ({
      ...prevControllers,
      [key]: newController,
    }));
    return newController;
  };

  const abortController = (key) => {
    if (controllers[key]) {
      controllers[key].abort();
      setControllers((prevControllers) => ({
        ...prevControllers,
        [key]: null,
      }));
    }
  };

  return (
    <AbortControllerContext.Provider value={{ controllers, createController, abortController }}>
      {children}
    </AbortControllerContext.Provider>
  );
};

// Custom hook to use the context
export const useAbortController = () => useContext(AbortControllerContext);
