import { useDispatch, useSelector } from "react-redux";
import { setLoginOpen } from "../reducers/stateSlice";

export const useLoginModal = () => {
  const loginOpen = useSelector((state) => state.main.modals.loginOpen);
  const dispatch = useDispatch();

  const onOpen = () => {
    dispatch(setLoginOpen(true));
  };

  const onClose = () => {
    dispatch(setLoginOpen(false));
  };

  return { isOpen: loginOpen, onOpen, onClose };
};
