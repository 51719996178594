import { convertSubcategoryToMainCategory } from ".";
import { DEFAULT_DAYS_FOR_ITINERARY } from "../consts/itinerary";

export function parseItineraryArray(itineraryArray) {
  let result = '["';
  let currentDay = -1;

  itineraryArray.forEach((item, index) => {
    // Insert day headers
    if (item.dayIndex !== currentDay) {
      if (index !== 0) {
        result += '"';
      }
      currentDay = item.dayIndex;
      result += `~DAY~ ${currentDay + 1}",`;
    }

    // Replace title in the description with title in angle brackets and category
    let itemDescription = item.activityBrief.replace(/\n/g, "").trim();
    // if (item.title) {
    //   const titleWithBracketsAndCategory = `<<${item.title}>> ≤≤${item.category}≥≥`;
    //   itemDescription = itemDescription.replace(new RegExp(item.title, "g"), titleWithBracketsAndCategory);
    // }
    if (item.title) {
      // Create a regex for the title to find an exact match
      const regex = new RegExp(`\\b${item.title}\\b`, "i");

      // Check if the title is found in the description
      if (regex.test(itemDescription)) {
        // Replace the title in the description with the formatted title
        itemDescription = itemDescription.replace(regex, `<<${item.title}>> ≤≤${item.category}≥≥`);
      } else {
        // Append the formatted title and category at the end if not found
        itemDescription += ` <<${item.title}>> ≤≤${item.category}≥≥`;
      }
    }

    result += `"${itemDescription} {}",`;
  });

  // Close the array
  result = result.slice(0, -1) + "]";
  return result;
}

export const cleanText = (text) => {
  return text.replace(/[\\/]/g, "");
};

export function getDaysFromSelectedDates(selectedDates) {
  if (!selectedDates || !selectedDates.length) {
    return DEFAULT_DAYS_FOR_ITINERARY;
  }
  function datediff(first, second) {
    first = typeof first === "string" ? new Date(first) : first;
    second = typeof second === "string" ? new Date(second) : second;
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  // parsing selectedDates if it is a string.
  let parsedDates = selectedDates;
  if (typeof selectedDates === "string") {
    parsedDates = JSON.parse(selectedDates);
  }

  return datediff(parsedDates[0], parsedDates[1]);
}

export function getActivityCategory(activity) {
  return convertSubcategoryToMainCategory(activity.category);
}

export function getActivityLink(activity) {
  const acitivityMainCategory = convertSubcategoryToMainCategory(activity.category);

  // if no booking links, return google maps link.
  if (!activity.bookingLinks || !activity.bookingLinks.length) {
    return `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${activity.googleDetails.placesId}`;
  }

  if (acitivityMainCategory === "lodging") {
    // if hotel, find the expedia link, if expedia link not found, return first link.
    const expediaLink = activity.bookingLinks?.find((link) => link.source === "Expedia.com");
    return expediaLink?.link || activity.bookingLinks[0]?.link;
  } else if (acitivityMainCategory === "dining") {
    // if dining, return the first link.
    return activity.bookingLinks[0]?.link;
  } else {
    // if other, return the first link.
    return activity.bookingLinks[0]?.link;
  }
}
