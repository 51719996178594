import React, { useState } from "react";
import {
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Icon,
  InputRightElement,
  Spinner,
  Link,
  Box,
} from "@chakra-ui/react";
import { IoMdCopy } from "react-icons/io";
import { CheckIcon } from "@chakra-ui/icons";

const ShareCreatorItinerary = ({ isOpen, onClose, shareUrl }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    // Copy text to clipboard
    navigator.clipboard.writeText(shareUrl);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={"xl"}>
      <ModalOverlay />
      <ModalContent py={"4rem"} px={"2rem"} borderRadius={"none"} border="3px solid black" backgroundColor={"base.100"}>
        <ModalCloseButton position="absolute" left={2} color={"black"}></ModalCloseButton>

        <VStack spacing={"1rem"}>
          <ModalHeader textAlign={"center"} p={0}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              {" "}
              Share your itinerary
            </Text>
            <Text fontSize={"md"} fontWeight={"normal"}>
              Copy and share the link with anyone!
            </Text>
          </ModalHeader>
          <HStack w={"100%"} justifyContent={"center"}>
            <VStack w="100%" m="0">
              <InputGroup my={"1rem"}>
                <Input
                  readOnly
                  value={shareUrl}
                  fontSize="sm"
                  backgroundColor={"white"}
                  borderRadius={"xl"}
                  border="2px"
                  color={"GrayText"}
                />
                <InputRightElement width={isCopied ? "24" : undefined}>
                  {isCopied ? (
                    <HStack>
                      <Text fontSize={"sm"}>Copied!</Text>{" "}
                      <Box
                        h="1.1rem"
                        w="1.1rem"
                        bg="brand.100"
                        borderRadius="full"
                        border="2px solid black"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CheckIcon fontSize="0.6rem" color="black" />
                      </Box>
                    </HStack>
                  ) : (
                    <Icon onClick={onCopy} color={"black"} boxSize={5} as={IoMdCopy} cursor={"pointer"}></Icon>
                  )}
                </InputRightElement>
              </InputGroup>
              <Link
                backgroundColor={"brand.100"}
                href={shareUrl}
                isExternal
                w="7.5rem"
                color={"white"}
                borderRadius={"lg"}
                border="2px solid black"
                textAlign={"center"}
                py={1}
                _hover={{ bg: "brand.200" }}
                fontWeight={"bold"}
              >
                VIEW
              </Link>
            </VStack>
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default ShareCreatorItinerary;
