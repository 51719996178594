import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    fontFamily: "Roboto",
    fontSize: "1.1rem",
    height: "40px",
    width: "100%",
    bg: "white",
    color: "black",
    _placeholder: {
      color: "#c0c0c0",
    },
  },
});

const primary = definePartsStyle({
  field: {
    border: "2px solid",
    borderColor: "black",
    outline: "black",
    borderRadius: "xl",

    _hover: {
      borderColor: "black",
    },
  },
});

export const inputStyles = defineMultiStyleConfig({
  baseStyle: baseStyle,
  variants: { primary: primary },
  defaultProps: { variant: "primary" },
});
