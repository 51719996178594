import { Button, Text } from "@chakra-ui/react";
import { loginWithTiktok } from "../services/InfluencerService";

export default function AccountDeleteSuccessful() {
  const handleLoginWithTiktok = async () => {
    try {
      const { data } = await loginWithTiktok();
      console.log("data", data);
      const url = data.tiktokAuthUrl;
      // redirect to tiktok login page
      window.open(url, "_self");
    } catch (error) {
      console.log("error logging in with tiktok", error);
    }
  };

  return (
    <div className="influencer-dashboard">
      <h1 className="heading">Account Deleted</h1>
      <Text fontWeight={900} mt={4}>
        This account was deleted.
      </Text>
      <Button className="request-transfer-button" onClick={handleLoginWithTiktok} mt={24}>
        Make new Account
      </Button>
    </div>
  );
}
