import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInfluencerItineraryById as getInfluencerItineraryByIdService } from "../services/InfluencerService";
import { getPublicSharedItineraryByUrl } from "../services/SharedItineraryService";

const initialState = {
  mapMode: false,
  gallery: [],
  tiktoks: [],
  modals: {
    loginOpen: false,
    gallery: false,
    tiktok: false,
  },
  getPublicSharedItineraryByUrl: {
    isLoading: false,
    error: null,
    data: null,
  },
  getInfluencerItineraryById: {
    isLoading: false,
    error: null,
    data: null,
  },
};

export const getInfluencerItineraryById = createAsyncThunk(
  "state/getInfluencerItineraryById",
  async ({ username, itineraryId }, { rejectWithValue }) => {
    try {
      const data = await getInfluencerItineraryByIdService(username, itineraryId);
      return data;
    } catch (error) {
      console.log("Error fetching influencer itinerary", error);
      rejectWithValue(error);
    }
  }
);

export const getPublicSharedItineraryByUrlThunk = createAsyncThunk(
  "state/getPublicSharedItineraryByUrl",
  async (url) => {
    const result = await getPublicSharedItineraryByUrl(url);
    return result;
  }
);

export const stateSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },

    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    setMapMode: (state, action) => {
      state.mapMode = action.payload;
    },

    setLoginOpen: (state, action) => {
      state.modals.loginOpen = action.payload;
    },

    setGallery: (state, action) => {
      let { images } = action.payload;
      state.gallery = images;
    },

    setTiktoks: (state, action) => {
      let { tiktoks } = action.payload;
      state.tiktoks = tiktoks;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getInfluencerItineraryById.pending, (state) => {
        state.getInfluencerItineraryById.isLoading = true;
      })
      .addCase(getInfluencerItineraryById.rejected, (state) => {
        state.getInfluencerItineraryById.isLoading = false;
        state.getInfluencerItineraryById.error = "Error getting shared itinerary";
      })
      .addCase(getInfluencerItineraryById.fulfilled, (state, action) => {
        state.getInfluencerItineraryById = {
          ...state.getInfluencerItineraryById,
          isLoading: false,
          data: action.payload,
        };
      })
      .addCase(getPublicSharedItineraryByUrlThunk.pending, (state) => {
        state.getPublicSharedItineraryByUrl.isLoading = true;
      })
      .addCase(getPublicSharedItineraryByUrlThunk.rejected, (state) => {
        state.getPublicSharedItineraryByUrl.isLoading = false;
        state.getPublicSharedItineraryByUrl.error = "Error getting public shared itinerary";
      })
      .addCase(getPublicSharedItineraryByUrlThunk.fulfilled, (state, action) => {
        state.getPublicSharedItineraryByUrl = {
          ...state.getPublicSharedItineraryByUrl,
          isLoading: false,
          data: action.payload,
        };
      });
  },
});

export const { setGallery, setTiktoks, setLoginOpen, setMapMode } = stateSlice.actions;

// app
export const selectGallery = (state) => state.main.gallery;
export const selectTiktoks = (state) => state.main.tiktoks;

export default stateSlice.reducer;

