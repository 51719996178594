import { Button, HStack, Icon, Spinner, Tooltip, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillPinFill } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";
import PlaceholderImage from "../../assets/placeholder.png";
import { FaPlay } from "react-icons/fa";

export default function TiktokItineraryCard({
  tiktokItinerary,
  username,
  showRegeneration,
  showPin,
  //  This is used to determine if the pin icon is view only or can be toggled.
  isPinEditable,
  // handles the click event for the pin icon.
  handlePinClick,
  // represents if the itinerary is pinned or not.
  isPinned,
  // represents if the itinerary is currently in process of being pinned or unpinned.
  isPinLoading,
  handleRegenerate,
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const [isRegenerating, setIsRegenerating] = useState(false);
  let createdTime = new Date(tiktokItinerary.createdAt);
  const yearString = new Date().getFullYear() === createdTime.getFullYear() ? "" : createdTime.getFullYear() + "-";
  createdTime = `${yearString}${createdTime.getMonth() + 1}-${createdTime.getDate()}`;

  const itineraryTitle = tiktokItinerary.title ? tiktokItinerary.title : "Itinerary is processing...";

  const handleNavigate = () => {
    if (tiktokItinerary.title) {
      // navigate to "/@username/itineraryid"
      navigate(`/${username}/${tiktokItinerary._id}`);
    } else {
      toast({
        title: "Itinerary is currently being processed. Please try again shortly!",
        status: "info",
        duration: 2500,
        isClosable: true,
      });
    }
  };

  return (
    <div className="tiktok-itinerary-card">
      {(showPin || showRegeneration) && (
        <HStack minHeight={"2rem"} mb={2}>
          {showPin && (
            <Tooltip label="Pin to the top of your profile!">
              <Button
                height={"2rem"}
                minWidth={"2rem"}
                border={"2px solid #000"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                p={0.5}
                borderRadius={6}
                bgColor={isPinned ? "#000" : "#808080"}
                cursor={isPinEditable ? "pointer" : "default"}
                onClick={() => {
                  handlePinClick(tiktokItinerary._id);
                }}
              >
                {isPinLoading ? (
                  <Spinner size={"sm"} color={"#fff"} />
                ) : (
                  <Icon w={5} h={5} as={BsFillPinFill} color={"#fff"} />
                )}
              </Button>
            </Tooltip>
          )}
          {showRegeneration && (
            <Button
              height={"2rem"}
              minWidth={"2rem"}
              border={"2px solid #000"}
              variant={"secondary"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              p={0.5}
              borderRadius={6}
              filter={tiktokItinerary.title ? undefined : "opacity(0.5)"}
              disabled={!tiktokItinerary.title}
              cursor={tiktokItinerary.title && !isRegenerating ? "pointer" : "default"}
              _hover={{
                filter: tiktokItinerary.title ? undefined : "opacity(0.5)",
              }}
              onClick={async () => {
                setIsRegenerating(true);
                await handleRegenerate(tiktokItinerary._id);
                setIsRegenerating(false);
              }}
            >
              {isRegenerating ? <Spinner /> : <Icon w={5} h={5} as={IoMdRefresh} color={"#fff"} />}
            </Button>
          )}
        </HStack>
      )}

      <div onClick={handleNavigate}>
        <div className="tiktok-thumbnail">
          <div className="play-btn-overlay">
            <Icon as={FaPlay} w={8} h={8} className="play-icon" />
          </div>
          <img src={tiktokItinerary.image || PlaceholderImage} alt={tiktokItinerary.title} />
          <p className="tiktok-creted-time">{createdTime}</p>
        </div>
        <p className="location">
          {tiktokItinerary.location?.name
            ? tiktokItinerary.location?.name
            : tiktokItinerary.location?.formatted_address}
        </p>
        <p className="title" style={{ fontWeight: "normal" }}>
          {itineraryTitle}
        </p>
      </div>
    </div>
  );
}
