import useDevice from "../../hooks/useDevice";
import { Box, Grid, GridItem, HStack, Heading, Image, Text, Button, Link } from "@chakra-ui/react";
import { getActivityCategory, getActivityLink } from "../../utils/itinerary";

function DailyItineraryContainer({ showDetailButtons, handleViewPrices, title, items, trackLinkClick }) {
  const { isTabletOrMobile } = useDevice();

  const getFormattedBrief = (description, activityName) => {
    // getting first occurence of activityName in description.
    const index = description?.indexOf(activityName);

    if (index !== -1) {
      const before = description.substring(0, index);
      const after = description.substring(index + activityName.length);

      return (
        <>
          <Text display={"inline"}>{before}</Text>
          <Text display={"inline"} fontWeight={"bold"} color="#f39d20">
            {activityName}
          </Text>
          <Text display={"inline"}>{after}</Text>
        </>
      );
    }
    // Return the description as is if activityName isn't found
    return <Text>{description}</Text>;
  };

  return (
    <Box w={"100%"}>
      <Box bg="#f5f1ff" p={2} borderY={"2px"}>
        <Heading as="h2" size="lg" textAlign="center" fontWeight="bold" textTransform={"uppercase"}>
          {title}
        </Heading>
      </Box>
      {items?.map((item, index) => (
        <Grid
          templateAreas={!isTabletOrMobile ? `"image description icon"` : `"image""icon""description"`}
          gridTemplateRows={isTabletOrMobile ? "220px 50px 1fr" : "1fr"}
          gridTemplateColumns={!isTabletOrMobile ? "220px 1fr 30px" : "1fr"}
          gap={isTabletOrMobile ? 1 : 8}
          p={isTabletOrMobile ? 4 : 8}
          mb={isTabletOrMobile ? 8 : 0}
          key={index}
        >
          <GridItem area={"image"} justifyContent={"center"} display="flex">
            <Link
              onClick={() => {trackLinkClick(getActivityCategory(item), item.title, "BookingTitleLinkClicked");}}
              href={getActivityLink(item)}
              target="_blank"
              display="flex"
              justifyContent="center"
              alignItems="center"
              w={"100%"}
            >
              <Image
                src={item.image}
                alt={item.title}
                maxH={isTabletOrMobile ? "100%" : 150}
                width={!isTabletOrMobile ? "100%" : "80%"}
                minH={isTabletOrMobile ? "100%" : 140}
                objectFit={"cover"}
                border={"2px"}
              />
            </Link>
          </GridItem>
          <GridItem area="description" display="flex" alignItems={"center"}>
            <Box>
              <HStack marginBottom={2} justifyContent={isTabletOrMobile ? "center" : "left"}>
                {/* circled item number marker */}
                <Box
                  backgroundColor={"#18d49f"}
                  borderRadius={"50%"}
                  minW={22}
                  h={22}
                  border={"2px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize={"xs"}
                  fontWeight={"extrabold"}
                >
                  {index + 1}
                </Box> 
                <Link onClick={() => {trackLinkClick(getActivityCategory(item), item.title, "BookingTitleLinkClicked");}} href={getActivityLink(item)} target="_blank">
                  <Heading
                    as="h3"
                    size="md"
                    fontWeight="bold"
                    textDecoration={"underline"}
                    fontFamily={"Rubik, sans-serif"}
                  >
                    {item.title}
                  </Heading>
                </Link>
              </HStack>
              <Box textAlign={isTabletOrMobile ? "center" : "left"} lineHeight={"short"}>
                {getFormattedBrief(item.activityBrief, item.title)}
              </Box>
              {showDetailButtons && item.bookingLinks && item.bookingLinks.length > 0 && (
                <HStack
                  marginTop={3}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={isTabletOrMobile ? "center" : "start"}
                  gap={2}
                  width={"100%"}
                  mx={"auto"}
                >
                  <Button
                    size={"sm"}
                    fontSize={"lg"}
                    onClick={(e) => {
                      handleViewPrices(item);
                    }}
                    width={270}
                    backgroundColor={"#E8FBEF"}
                    color={"black"}
                    rounded={"xl"}
                    border={"2px solid #000"}
                  >
                    View Details & Prices
                  </Button>
                </HStack>
              )}
            </Box>
          </GridItem>
        </Grid>
      ))}
    </Box>
  );
}
export default DailyItineraryContainer;
