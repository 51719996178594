export const lodging_subcategories = [
  "3_star_hotels",
  "4_star_hotels",
  "5_star_hotels",
  "boutique_hotels",
  "luxury_resorts",
  "eco_hotels",
  "wellness_hotels",
  "bed_and_breakfasts",
  "all_inclusives",
  "cabin_rentals",
  "unique_stays",
  "airbnbs",
  "villas",
  "beach_houses",
  "apartments",
  "farm_stays",
  "budget_hostels",
  "backpacker_hostels",
  "party_hostels",
  "eco_hostels",
  "campgrounds",
  "rv_parks",
  "glamping_sites",
  "wilderness_campsites",
  "national_park_lodgings",
];

export const dining_subcategories = ["quick_service", "fine_dining", "casual_dining", "cafe", "street_food"];

export const activity_subcategories = [
  "spa",
  "shop",
  "bookstore",
  "casino",
  "clubs",
  "thrift_stores",
  "malls",
  "souvenir_shops",
  "antique_shops",
  "specialty_stores",
  "museums",
  "galleries",
  "historic_sites",
  "landmarks",
  "hot_springs",
  "zoos",
  "theme_parks",
  "beaches",
  "hikes",
  "religious_places",
  "murals_artwork",
  "botanical_gardens",
  "pools",
  "national_parks",
  "aquariums",
  "science_centers",
  "observation_decks",
  "planetariums",
  "wildlife_reserves",
  "state_parks",
  "city_parks",
  "war_memorials",
  "cultural_heritage_sites",
  "art_installations",
  "natural_wonders",
  "caves",
  "waterfalls",
  "guided_tours",
  "boat_tours",
  "biking_tours",
  "food_tours",
  "audio_tours",
  "helicopter_tours",
  "bus_tours",
  "train_tours",
  "walking_tours",
  "photography_tours",
  "cultural_tours",
  "nature_tours",
  "ghost_tours",
  "paragliding",
  "hot_air_balloon_rides",
  "scuba_diving",
  "horseback_riding",
  "wine_tasting",
  "skiing",
  "snowboarding",
  "snowmobile_rides",
  "kayaking",
  "rock_climbing",
  "stargazing",
  "snorkeling",
  "fishing_trips",
  "whale_watching",
  "safari_expeditions",
  "zip-lining",
  "bungee_jumping",
  "whitewater_rafting",
  "deep-sea_fishing",
  "canopy_tours",
  "sandboarding",
  "ice_climbing",
  "wildlife_safaris",
  "cultural_immersion_excursions",
  "off-roading",
  "sailing",
  "surfing",
  "trekking",
  "caving",
  "sports_events",
  "concerts",
  "musicals",
  "plays",
  "flea_markets",
  "food_festivals",
  "farmers_markets",
  "art_exhibits",
  "cultural_festivals",
  "parades",
  "street_performances",
  "film_festivals",
  "book_fairs",
  "cultural_parades",
  "live_comedy_shows",
  "street_festivals",
  "wine_festivals",
  "craft_beer_tastings",
  "rodeos",
  "air_shows",
  "auto_shows",
  "expos",
  "religious_festivals",
  "historical_celebrations",
  "cooking_classes",
  "photography_classes",
  "yoga_classes",
  "surfing_lessons",
  "dance_classes",
  "language_classes",
  "painting_classes",
  "craft_workshops",
];

export const subcategory_map = {
  spa: "retail_and_services",
  shop: "retail_and_services",
  bookstore: "retail_and_services",
  casino: "retail_and_services",
  clubs: "retail_and_services",
  thrift_stores: "retail_and_services",
  malls: "retail_and_services",
  souvenir_shops: "retail_and_services",
  antique_shops: "retail_and_services",
  specialty_stores: "retail_and_services",
  museums: "attraction",
  galleries: "attraction",
  historic_sites: "attraction",
  landmarks: "attraction",
  hot_springs: "attraction",
  zoos: "attraction",
  theme_parks: "attraction",
  beaches: "attraction",
  hikes: "attraction",
  religious_places: "attraction",
  murals_artwork: "attraction",
  botanical_gardens: "attraction",
  pools: "attraction",
  national_parks: "attraction",
  aquariums: "attraction",
  science_centers: "attraction",
  observation_decks: "attraction",
  planetariums: "attraction",
  wildlife_reserves: "attraction",
  state_parks: "attraction",
  city_parks: "attraction",
  war_memorials: "attraction",
  cultural_heritage_sites: "attraction",
  art_installations: "attraction",
  natural_wonders: "attraction",
  caves: "attraction",
  waterfalls: "attraction",
  guided_tours: "tour",
  boat_tours: "tour",
  biking_tours: "tour",
  food_tours: "tour",
  audio_tours: "tour",
  helicopter_tours: "tour",
  bus_tours: "tour",
  train_tours: "tour",
  walking_tours: "tour",
  photography_tours: "tour",
  cultural_tours: "tour",
  nature_tours: "tour",
  ghost_tours: "tour",
  paragliding: "excursion",
  hot_air_balloon_rides: "excursion",
  scuba_diving: "excursion",
  horseback_riding: "excursion",
  wine_tasting: "excursion",
  skiing: "excursion",
  snowboarding: "excursion",
  snowmobile_rides: "excursion",
  kayaking: "excursion",
  rock_climbing: "excursion",
  stargazing: "excursion",
  snorkeling: "excursion",
  fishing_trips: "excursion",
  whale_watching: "excursion",
  safari_expeditions: "excursion",
  zip_lining: "excursion",
  bungee_jumping: "excursion",
  whitewater_rafting: "excursion",
  deep_sea_fishing: "excursion",
  canopy_tours: "excursion",
  sandboarding: "excursion",
  ice_climbing: "excursion",
  wildlife_safaris: "excursion",
  cultural_immersion_excursions: "excursion",
  off_roading: "excursion",
  sailing: "excursion",
  surfing: "excursion",
  trekking: "excursion",
  caving: "excursion",
  sports_events: "events",
  concerts: "events",
  musicals: "events",
  plays: "events",
  flea_markets: "events",
  food_festivals: "events",
  farmers_markets: "events",
  art_exhibits: "events",
  cultural_festivals: "events",
  parades: "events",
  street_performances: "events",
  film_festivals: "events",
  book_fairs: "events",
  cultural_parades: "events",
  live_comedy_shows: "events",
  street_festivals: "events",
  wine_festivals: "events",
  craft_beer_tastings: "events",
  rodeos: "events",
  air_shows: "events",
  auto_shows: "events",
  expos: "events",
  religious_festivals: "events",
  historical_celebrations: "events",
  cooking_classes: "class",
  photography_classes: "class",
  yoga_classes: "class",
  surfing_lessons: "class",
  dance_classes: "class",
  language_classes: "class",
  painting_classes: "class",
  craft_workshops: "class",
  meditation_retreats: "class",
  "3_star_hotels": "hotel",
  "4_star_hotels": "hotel",
  "5_star_hotels": "hotel",
  boutique_hotels: "hotel",
  luxury_resorts: "hotel",
  eco_hotels: "hotel",
  wellness_hotels: "hotel",
  bed_and_breakfasts: "hotel",
  all_inclusives: "hotel",
  cabin_rentals: "vacation_rentals",
  unique_stays: "vacation_rentals",
  airbnbs: "vacation_rentals",
  villas: "vacation_rentals",
  beach_houses: "vacation_rentals",
  apartments: "vacation_rentals",
  farm_stays: "vacation_rentals",
  budget_hostels: "hostel",
  backpacker_hostels: "hostel",
  party_hostels: "hostel",
  eco_hostels: "hostel",
  campgrounds: "campsites",
  rv_parks: "campsites",
  glamping_sites: "campsites",
  wilderness_campsites: "campsites",
  national_park_lodgings: "campsites",

  quick_service: "restaurants",
  fine_dining: "restaurants",
  casual_dining: "restaurants",
  cafe: "restaurants",
  street_food: "restaurants",
};

export const category_map = {
  other: "other",
  dining: "dining",
  lodging: "lodging",
  spa: "other",
  shop: "other",
  bookstore: "other",
  casino: "other",
  clubs: "other",
  thrift_stores: "other",
  malls: "other",
  souvenir_shops: "other",
  antique_shops: "other",
  specialty_stores: "other",
  museums: "other",
  galleries: "other",
  historic_sites: "other",
  landmarks: "other",
  hot_springs: "other",
  zoos: "other",
  theme_parks: "other",
  beaches: "other",
  hikes: "other",
  religious_places: "other",
  murals_artwork: "other",
  botanical_gardens: "other",
  pools: "other",
  national_parks: "other",
  aquariums: "other",
  science_centers: "other",
  observation_decks: "other",
  planetariums: "other",
  wildlife_reserves: "other",
  state_parks: "other",
  city_parks: "other",
  war_memorials: "other",
  cultural_heritage_sites: "other",
  art_installations: "other",
  natural_wonders: "other",
  caves: "other",
  waterfalls: "other",
  guided_tours: "other",
  boat_tours: "other",
  biking_tours: "other",
  food_tours: "other",
  audio_tours: "other",
  helicopter_tours: "other",
  bus_tours: "other",
  train_tours: "other",
  walking_tours: "other",
  photography_tours: "other",
  cultural_tours: "other",
  nature_tours: "other",
  ghost_tours: "other",
  paragliding: "other",
  hot_air_balloon_rides: "other",
  scuba_diving: "other",
  horseback_riding: "other",
  wine_tasting: "other",
  skiing: "other",
  snowboarding: "other",
  snowmobile_rides: "other",
  kayaking: "other",
  rock_climbing: "other",
  stargazing: "other",
  snorkeling: "other",
  fishing_trips: "other",
  whale_watching: "other",
  safari_expeditions: "other",
  zip_lining: "other",
  bungee_jumping: "other",
  whitewater_rafting: "other",
  deep_sea_fishing: "other",
  canopy_tours: "other",
  sandboarding: "other",
  ice_climbing: "other",
  wildlife_safaris: "other",
  cultural_immersion_excursions: "other",
  off_roading: "other",
  sailing: "other",
  surfing: "other",
  trekking: "other",
  caving: "other",
  sports_events: "other",
  concerts: "other",
  musicals: "other",
  plays: "other",
  flea_markets: "other",
  food_festivals: "other",
  farmers_markets: "other",
  art_exhibits: "other",
  cultural_festivals: "other",
  parades: "other",
  street_performances: "other",
  film_festivals: "other",
  book_fairs: "other",
  cultural_parades: "other",
  live_comedy_shows: "other",
  street_festivals: "other",
  wine_festivals: "other",
  craft_beer_tastings: "other",
  rodeos: "other",
  air_shows: "other",
  auto_shows: "other",
  expos: "other",
  religious_festivals: "other",
  historical_celebrations: "other",
  cooking_classes: "other",
  photography_classes: "other",
  yoga_classes: "other",
  surfing_lessons: "other",
  dance_classes: "other",
  language_classes: "other",
  painting_classes: "other",
  craft_workshops: "other",
  quick_service: "dining",
  fine_dining: "dining",
  casual_dining: "dining",
  cafe: "dining",
  street_food: "dining",
  "3_star_hotels": "lodging",
  "4_star_hotels": "lodging",
  "5_star_hotels": "lodging",
  boutique_hotels: "lodging",
  luxury_resorts: "lodging",
  eco_hotels: "lodging",
  wellness_hotels: "lodging",
  bed_and_breakfasts: "lodging",
  all_inclusives: "lodging",
  cabin_rentals: "lodging",
  unique_stays: "lodging",
  airbnbs: "lodging",
  villas: "lodging",
  beach_houses: "lodging",
  apartments: "lodging",
  farm_stays: "lodging",
  budget_hostels: "lodging",
  backpacker_hostels: "lodging",
  party_hostels: "lodging",
  eco_hostels: "lodging",
  campgrounds: "lodging",
  rv_parks: "lodging",
  glamping_sites: "lodging",
  wilderness_campsites: "lodging",
  national_park_lodgings: "lodging",
};
