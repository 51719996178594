import React from "react";
import { Heading, Text, VStack } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";

export default function StatsCard({ title, value }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <VStack alignItems={"flex-start"} minW={isTabletOrMobile ? "100%" : 340} py={6} px={8} border={"2px solid #000"}>
      <Heading fontSize={"1.5rem"}>{title}</Heading>
      <Text fontSize={"2rem"} fontWeight={700}>
        {value}
      </Text>
    </VStack>
  );
}
