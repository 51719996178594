import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Box, Container, HStack, Image, Text, Button, VStack, Spinner, useDisclosure } from "@chakra-ui/react";

import useDevice from "../../hooks/useDevice";
import Logo from "./../../assets/brand/logo_big.png";
import Login from "../../features/Login/Login";
import DailyItineraryContainer from "./DailyItineraryContainer";
import { getPublicSharedItineraryByUrlThunk } from "../../reducers/stateSlice";
import BookingLinksModal from "../../modals/BookingLinksModal/BookingLinksModal";

const PublicSharedItinerary = () => {
  const { tag, url } = useParams();
  const navigate = useNavigate();
  const { isTabletOrMobile } = useDevice();

  const {
    isOpen: isBookingLinksModalOpen,
    onOpen: onBookingLinksModalOpen,
    onClose: onBookingLinksModalClose,
  } = useDisclosure();

  const [selectedActivityForBookingModal, setSelectedActivityForBookingModal] = useState(null);

  const handleOpenBookingLinksModal = (activityDetails) => {
    setSelectedActivityForBookingModal(activityDetails);
    onBookingLinksModalOpen();
  };

  const { isLoading, data: itineraryData } = useSelector((state) => state.main.getPublicSharedItineraryByUrl);

  const dispatch = useDispatch();

  const handleTitleClick = async (item) => {
    console.log("Title clicked: ", item);
  };

  const handleSave = () => {
    console.log("Save clicked");
  };

  const defaultImage = "https://miro.medium.com/v2/resize:fit:1400/1*TzaiFDmkiEkOxNU7eG43pw.jpeg";

  useEffect(() => {
    if (url) {
      window.heap && window.heap.track("Generated Public Shared Page");
      dispatch(getPublicSharedItineraryByUrlThunk(tag + "/" + url));
    }
  }, [url, tag, dispatch]);

  return (
    <Box bg="base.300" height={"100%"} overflow={"auto"}>
      {/* <Button
        onClick={() => {
          onSharePageModalOpen();
        }}
      >
        Open
      </Button> */}

      <Login display="none"></Login>
      <BookingLinksModal
        activityDetails={selectedActivityForBookingModal}
        onClose={onBookingLinksModalClose}
        onOpen={onBookingLinksModalOpen}
        isOpen={isBookingLinksModalOpen}
      ></BookingLinksModal>
      {isLoading ? (
        <Container maxW="container.lg" centerContent>
          <Box
            width={"100%"}
            minH={"110vh"}
            border="2px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Spinner size="xl" />
          </Box>
        </Container>
      ) : (
        <Container maxW="container.lg" centerContent p={isTabletOrMobile ? 0 : undefined} position={"relative"}>
          {/* Header Box */}
          <Box minW="100%" padding="0" border={isTabletOrMobile ? "0px" : "2px"} position={"relative"}>
            <HStack>
              {!isTabletOrMobile ? (
                // Image which shows on left
                <Box flex="1" border={"2px"} minH={"20rem"}>
                  <Image
                    objectFit="cover"
                    src={itineraryData?.data?.image || defaultImage}
                    alt={itineraryData?.data?.title}
                  />
                </Box>
              ) : (
                // Image which shown on background on mobile
                <Image
                  src={itineraryData?.data?.image || defaultImage}
                  alt={itineraryData?.data?.title}
                  position={"absolute"}
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  objectFit={"cover"}
                  zIndex={0}
                  opacity={0.5}
                />
              )}
              <Box flex="1" padding={4} zIndex={0}>
                <Link to="/home">
                  <Image
                    position={"absolute"}
                    top={4}
                    right={!isTabletOrMobile ? 6 : undefined}
                    left={!isTabletOrMobile ? undefined : 4}
                    maxH={50}
                    objectFit="contain"
                    src={Logo}
                    alt="Itair logo"
                  />
                </Link>
                <Text
                  fontFamily={"Rubik, sans-serif"}
                  fontSize={"5xl"}
                  fontWeight={"bold"}
                  lineHeight={"shorter"}
                  paddingTop={isTabletOrMobile ? "50px" : "0px"}
                  px={isTabletOrMobile ? "20px" : "0px"}
                  textAlign={isTabletOrMobile ? "center" : "left"}
                >
                  {itineraryData?.data?.title}
                </Text>

                <Box
                  marginTop={6}
                  display={"flex"}
                  flexDirection={isTabletOrMobile ? "column" : "row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={2}
                  width={isTabletOrMobile ? "60%" : "100%"}
                  mx={"auto"}
                >
                  <Button
                    width={"100%"}
                    backgroundColor={"#232323"}
                    color={"white"}
                    rounded={"2xl"}
                    onClick={() => {
                      // redirect to /home page.
                      navigate("/");
                    }}
                  >
                    Create New Trip
                  </Button>
                  <Button
                    onClick={(e) => {
                      console.log("Edit this trip clicked");
                    }}
                    width={"100%"}
                    backgroundColor={"#18d49f"}
                    color={"white"}
                    rounded={"2xl"}
                  >
                    Edit This Trip
                    {/* redirect to /itinerary page and load this itinerary into state. */}
                  </Button>
                </Box>
              </Box>
            </HStack>
          </Box>
          <VStack border={"2px"} borderBottom={"none"} paddingTop={2} mb={"72px"} overflow={"auto"}>
            {itineraryData?.data.days.map((items, index) => (
              <DailyItineraryContainer
                showDetailButtons={true}
                handleViewPrices={handleOpenBookingLinksModal}
                handleTitleClick={handleTitleClick}
                key={index}
                title={`Day ${index + 1}`}
                items={items}
                handleSave={handleSave}
              />
            ))}
          </VStack>
          {/* Edit itinerary prompt input
          <Container
            h={"72px"}
            maxW="container.lg"
            centerContent
            position={"fixed"}
            bottom={0}
            width={"100%"}
            p={isTabletOrMobile ? 0 : undefined}
          >
            <FormControl
              px="2"
              py="4"
              borderX={"2px"}
              borderTop={"2px"}
              display={"flex"}
              alignItems={"center"}
              columnGap={2}
              backgroundColor={"#f0fcf4"}
            >
              <Input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="What changes do you want to make to this itinerary?"
                border={"2px"}
                backgroundColor={"white"}
                _placeholder={{ color: "#4d4d4d" }}
              />
              <Button
                backgroundColor={"#232323"}
                color={"white"}
                maxH={"32px"}
                w={"100px"}
                rounded={"xl"}
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Update
              </Button>
            </FormControl>
          </Container> */}
        </Container>
      )}
    </Box>
  );
};

export default PublicSharedItinerary;

