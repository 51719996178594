import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, HStack, VStack, Text } from "@chakra-ui/react";

import { FaTiktok } from "react-icons/fa";
import { loginWithTiktok } from "../services/InfluencerService";

function Login() {
  const handleLoginWithTiktok = async () => {
    try {
      const { data } = await loginWithTiktok();
      console.log("data", data);
      const url = data.tiktokAuthUrl;

      // redirect to tiktok login page
      window.open(url, "_self");
    } catch (error) {
      console.log("error logging in with tiktok", error);
    }
  };

  return (
    <VStack w={"100%"} h={"100%"} backgroundColor={"#fff4de"} justifyContent={"space-between"}>
      {/* Nav */}
      <HStack align={"center"} justifyContent={"start"} w={"full"} pl={"2.5rem"} pt={"0.5rem"}>
        <Link to={""}>
          <img
            src="images/Itair-Logo-1.png"
            loading="lazy"
            sizes="(max-width: 479px) 110px, 150px"
            srcSet="images/Itair-Logo-1-p-500.png 500w, images/Itair-Logo-1-p-800.png 800w, images/Itair-Logo-1.png 1076w"
            alt=""
          />
        </Link>
      </HStack>

      <Box>
        <Text fontSize={"2rem"} fontWeight={"bold"} textAlign={"center"} pt={"2rem"} fontFamily={"heading"}>
          Ready to monetize your content?
        </Text>

        <Text fontSize={"1.25rem"} fontWeight={"semibold"} textAlign={"center"} pt={"1rem"} fontFamily={"heading"}>
          Sign up below to get started!
        </Text>

        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"150px"}>
          <Button
            onClick={handleLoginWithTiktok}
            bg="black"
            fontWeight={"medium"}
            fontSize={"1rem"}
            minWidth={"280px"}
            position={"relative"}
            minHeight={"44px"}
          >
            <FaTiktok color="white" size={"1.25rem"} style={{ position: "absolute", left: "1.25rem" }} />
            Sign in with Tiktok
          </Button>
        </Box>
      </Box>

      <div
        style={{
          gap: "10px",
          paddingBottom: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="/privacy.html">privacy policy</a>
        <a href="/tos.html">terms of service</a>
      </div>
    </VStack>
  );
}

export default Login;

