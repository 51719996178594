import { useEffect, useState } from "react";
import { deleteCookie, getCookie } from "../utils";
import { getInfluencerById } from "../services/InfluencerService";

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [influencer, setInfluencer] = useState(undefined);

  const influencerId = getCookie("influencerId");

  // Fetch influencer details if influencerId is available
  useEffect(() => {
    const getInfluencer = async () => {
      if (influencerId) {
        try {
          setIsLoading(true);
          const { data } = await getInfluencerById(influencerId);
          if (data) {
            setInfluencer(data);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          console.error("error fetching influencer details:", error);
        }
      }
    };
    getInfluencer();
  }, [influencerId]);

  const logout = async () => {
    try {
      if (influencer) {
        setInfluencer(undefined);
        // clear the the influencerId cookie.
        deleteCookie("influencerId");
        return;
      }
    } catch (error) {
      console.error("error logging user out:", error);
    }
  };

  return { influencer, logout, isLoading };
};

