import { extendTheme } from "@chakra-ui/react";
import { buttonStyles } from "./components/button";
import { colors } from "./colors";
import { inputStyles } from "./components/input";

const overrides = {
  colors,
  components: {
    Button: buttonStyles,
    Input: inputStyles,
  },
  fonts: {
    heading: "Rubik, san-serif",
    body: "Roboto, san-serif",
  },
};

export const theme = extendTheme(overrides);
