import React from "react";
import { Image, Box } from "@chakra-ui/react";

function LodgingBookingLinkIcon({ name: brand, width = "1rem" }) {
  let imgSrc = "";
  switch (brand.toLowerCase()) {
    case "tripadvisor.com":
      imgSrc = "//www.gstatic.com/travel-hotels/branding/6dcb5a28-6742-4ce4-9beb-07c31138a20b.png";
      break;
    case "expedia.com":
      imgSrc = "https://www.gstatic.com/travel-hotels/branding/ac238c97-1652-4830-8da8-bb8d8883af88.png";
      break;
    case "hotels.com":
      imgSrc = "//www.gstatic.com/travel-hotels/branding/1bf6ef2c-9c95-4ddd-ae11-bc9bdf419004.png";
      break;
    case "booking.com":
      imgSrc = "//www.gstatic.com/travel-hotels/branding/icon_184.png";
      break;
    case "priceline":
      imgSrc = "//www.gstatic.com/travel-hotels/branding/icon_220.png";
      break;
    case "airbnb":
      imgSrc = "https://companieslogo.com/img/orig/ABNB-4aaade0f.png?t=1633511992";
      break;
    case "viator":
      imgSrc = "https://cdn.theorg.com/116f2d73-259e-490d-8bfa-d30527357618_thumb.jpg";
      break;
    case "getyourguide":
      imgSrc =
        "https://cdn.getyourguide.com/tf/assets/static/one-trust-latest/consent/14c73fac-9c05-4db0-b3d0-e7df76c50956/018f770d-7d7c-775c-84a6-bbe9b0dae19a/logos/a3cf7bf5-030b-48ef-96ac-1e0dbfe8e469/5698bdaa-ca4c-464e-8959-781769ba2c55/GYG_Symbol_Red_RGB_HR.png";
      break;
    case "trip.com":
      imgSrc =
        "https://play-lh.googleusercontent.com/XP13OLtNtCincmIUiQmuHUhoq1RwkCaN9uV9zafCOhXrxFSTMPLTUpChh3P9yfbGBg=w480-h960-rw";
      break;
    default:
      imgSrc = "https://webvision.med.utah.edu/wp-content/uploads/2012/06/50-percent-gray.jpg";
  }

  return (
    <Box w={width} alignItems={"center"} justifyContent={"center"}>
      <Image src={imgSrc} height={width} width={width} minW={width}></Image>
    </Box>
  );
}

export default LodgingBookingLinkIcon;

