import axios from "axios";
import { BACKEND_URL } from "../consts/host";

// Login with Tiktok.
export async function loginWithTiktok() {
  const url = `${BACKEND_URL}/auth/tiktok`;
  const result = await axios.get(url);
  return result;
}

// Get influencer's details.
export async function getInfluencerById(influencerId) {
  const url = `${BACKEND_URL}/api/influencer/${influencerId}`;
  const result = await axios.get(url);
  return result;
}

// The creator welcome page.
export async function getInfluencerVideos(influencerId, cursor) {
  const url = `${BACKEND_URL}/api/influencer/${influencerId}/videos?cursor=${cursor}`;
  return await axios.get(url);
}

// The creator welcome page.
export async function getInfluencerPopulatedVideos(influencerId, cursor) {
  const url = `${BACKEND_URL}/api/influencer/${influencerId}/populated-videos?cursor=${cursor}`;
  return await axios.get(url);
}

// for submission of videos to process.
export async function submitVideosForProcessing(influencerId, tiktoks, lastSyncedVideoTimeStamp) {
  // send an array of the share urls of the videos to process, along with the influencer id.
  const url = `${BACKEND_URL}/process-tiktok/batch`;

  const body = {
    influencerId,
    tiktoks,
    lastSyncedVideoTimeStamp,
  };

  const result = await axios.post(url, body);
  return result;
}

// for updatation of videos to process.
export async function updateVideosForProcessing(influencerId, tiktoks) {
  // send an array of the share urls of the videos to process, along with the influencer id.
  const url = `${BACKEND_URL}/process-tiktok/batch`;

  const body = {
    influencerId,
    tiktoks,
  };

  const result = await axios.put(url, body);
  return result;
}

// for regeneration of already prcessed itineraries.
export async function regenerateItinerary(influencerId, itineraryId) {
  // send an array of the share urls of the videos to process, along with the influencer id.
  const url = `${BACKEND_URL}/process-tiktok/regenerate`;

  const body = {
    influencerId,
    itineraryId,
  };

  const result = await axios.post(url, body);
  return result;
}

// The creator's profile page.
// for /influencer/:username
export async function getInfluencerProfileDetails(username) {
  const url = `${BACKEND_URL}/api/influencer/profile/${username}`;
  return await axios.get(url);
}

export async function getInfluencerClaimProfileDetails(username) {
  const url = `${BACKEND_URL}/api/influencer/claim-profile/${username}`;
  return await axios.get(url);
}

// Pin or unpin itinerary for the influencer.
export async function togglePinForItinerary(influencerId, itineraryId, isPinned) {
  const url = `${BACKEND_URL}/api/influencer/${influencerId}/pin`;
  const body = {
    isPinned,
    itineraryId,
  };
  return await axios.post(url, body);
}

// The creator's dashboard page.
// for /influencer/dashboard
export async function getInfluencerStats(influencerId) {
  await new Promise((resolve) => setTimeout(resolve, 500));
  const url = `${BACKEND_URL}/api/influencer/${influencerId}/dashboard`;
  return await axios.get(url);
}

export async function getInfluencerItineraryById(username, itineraryId) {
  const url = `${BACKEND_URL}/api/influencer/${username}/itineraries/${itineraryId}`;
  return await axios.get(url);
}

export async function deleteInfuencerClaimProfile(username) {
  const url = `${BACKEND_URL}/api/influencer/claim-profile/${username}`;
  return await axios.delete(url);
}

// Update influencer's AI preference
export async function updateInfluencerAIPreference(influencerId, allowAI) {
  const url = `${BACKEND_URL}/api/influencer/${influencerId}/ai-preference`;
  const body = { allowAI };
  return await axios.put(url, body);
}

