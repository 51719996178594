import React from "react";
import "./TiktokSelector.css";
import { IoMdCheckmark } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { Box, Button, HStack, Icon } from "@chakra-ui/react";
import { BsFillPinFill } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";

export default function TiktokSelector({
  tiktok,
  isSelected,
  toggleSelection,
  showRegeneration,
  showPin,

  // This is used to determine if the pin icon is view only or can be toggled.
  isPinEditable,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  let createdTime = new Date(tiktok.create_time * 1000);
  const yearString = new Date().getFullYear() === createdTime.getFullYear() ? "" : createdTime.getFullYear() + "-";
  createdTime = `${yearString}${createdTime.getMonth() + 1}-${createdTime.getDate()}`;
  return (
    <div className="tiktok-selector" onClick={isTabletOrMobile ? () => toggleSelection(tiktok) : undefined}>
      {(showRegeneration || showPin) && (
        <HStack mb={2}>
          {showPin && (
            <Button
              border={"2px solid #000"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              p={1.5}
              borderRadius={6}
              bgColor={"#808080"}
              cursor={isPinEditable ? "pointer" : "default"}
            >
              <Icon w={6} h={6} as={BsFillPinFill} color={"#fff"} />
            </Button>
          )}
          {showRegeneration && (
            <Button
              border={"2px solid #000"}
              variant={"secondary"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              p={1.5}
              borderRadius={6}
            >
              <Icon w={6} h={6} as={IoMdRefresh} color={"#fff"} />
            </Button>
          )}
        </HStack>
      )}
      <div className="tiktok-thumbnail">
        <div
          className={`tiktok-checkbox ${isSelected ? "checked" : ""}`}
          onClick={isTabletOrMobile ? undefined : () => toggleSelection(tiktok)}
        >
          {isSelected && <IoMdCheckmark />}
        </div>
        <img src={tiktok.cover_image_url} alt={tiktok.title} />
        <p className="tiktok-creted-time">{createdTime}</p>
      </div>
    </div>
  );
}
