export const buttonStyles = {
  // 1. We can update the base styles
  baseStyle: {
    fontFamily: "Roboto",
    borderRadius: "md",
    border: "1px solid #000",
    fontSize: "1.1rem",
    height: "40px",
    color: "white",
    transition: "all 0.15s ease-in-out",
    _hover: {
      filter: "brightness(95%)",
      // bgImage: "linear-gradient(rgb(0 0 0/10%) 0 0);",
    },
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    sm: {
      fontSize: "0.75rem",
    },
  },
  // 3. We can add a new visual variant
  variants: {
    primary: {
      bg: "brand.100",
    },
    secondary: {
      bg: "purple.100",
    },
    error: {
      bg: "red.500",
    },
  },
  // 6. We can overwrite defaultProps
  defaultProps: {
    variant: "primary",
  },
};
