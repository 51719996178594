import dining_img from "../assets/activityCategories/dining_img.png";
import lodging_img from "../assets/activityCategories/lodging_img.png";
import other_img from "../assets/activityCategories/other_img.png";
import { IoBed } from "react-icons/io5";
import { ImSpoonKnife } from "react-icons/im";
import { MdWaves } from "react-icons/md";
import { category_map } from "../consts/subcategories";

/**
 * Converts a subcategory to its corresponding main category.
 * @param {string} subcategory - The subcategory to be converted.
 * @returns {string} - The main category corresponding to the subcategory.
 */
export function convertSubcategoryToMainCategory(subcategory) {
  if (subcategory in category_map) {
    return category_map[subcategory];
  } else {
    return "other";
  }
}

export function truncateString(str, length) {
  // Check if the string length is greater than length characters
  if (str?.length > length) {
    // If so, truncate the string to the first allowed length characters and append '...'
    return str.substring(0, length) + "...";
  } else {
    // If the string is required characters or less, return it as is
    return str;
  }
}

export const breakArrayIntoChunks = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

/**
 * Check weather the text contains numbers
 * @param {string} text string for test
 * @returns {boolean} flag if text contains number
 */
export const hasNumber = (text) => {
  return /\d/.test(text);
};

/**
 * Accepts a raw text with single qoutes and double qoutes and return it by renoving all the qoutes from it
 * @param {string} rawTitle raw text with qoutes
 * @returns {string} clean text
 */
export const cleanText = (rawTitle) => {
  if (!rawTitle) return rawTitle;
  let title = rawTitle.replace(/['"]+/g, "");
  return title
    .split(" ")
    .map((word) => {
      return word[0]?.toUpperCase() + word.substring(1);
    })
    .join(" ");
};

/**
 * Provides the category image based ont the category name
 * @param {string} category Category name
 * @returns {string} Category Image string
 */
export const getCategoryImage = (category) => {
  switch (category.toLowerCase()) {
    case "dining":
      return dining_img;
    case "lodging":
      return lodging_img;
    default:
      return other_img;
  }
};

/**
 * Renders the category icon based on the category name
 * @param {string} category Category Name
 * @param {boolean} isTabletOrMobile Boolean flag to detemine if app is in mobile view or desktop view
 * @returns {import("react").ReactNode} Category Icon
 */
export const renderCategoryIcon = (category, isTabletOrMobile = false) => {
  let icon = <MdWaves size={isTabletOrMobile ? "1.4rem" : "1.6rem"} />;

  switch (convertSubcategoryToMainCategory(category.toLowerCase())) {
    case "dining":
      icon = <ImSpoonKnife size={isTabletOrMobile ? "1.4rem" : "1.6rem"} />;
      break;
    case "lodging":
      icon = <IoBed size={isTabletOrMobile ? "1.4rem" : "1.6rem"} />;
      break;
    default:
      break;
  }
  return icon;
};

/**
 * Calculates fontSize for any text depending on the length of text and screen size
 * @param {string} text
 * @param {boolean} isTabletOrMobile Boolean flag to detemine if app is in mobile view or desktop view
 * @returns {string}
 */
export const calculateFontSize = (text, isTabletOrMobile = false) => {
  if (text) {
    if (isTabletOrMobile) {
      if (text.length < 28) return "1rem";
      else if (text.length >= 28 && text.length < 32) return "0.7rem";
      else if (text.length >= 32 && text.length < 50) {
        return "0.45rem";
      } else {
        return "0.45rem";
      }
    } else {
      if (text.length < 28) return "1.8rem";
      else if (text.length >= 28 && text.length < 32) return "1.5rem";
      else if (text.length >= 32 && text.length < 50) {
        return "1.2rem";
      } else {
        return "0.9rem";
      }
    }
  }
  return isTabletOrMobile ? "1rem" : "1.4rem";
};

// util function to get cookie by key.
export function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function deleteCookie(cookieName) {
  const cookieValue = document.cookie.split("; ").find((row) => row.startsWith(cookieName + "="));
  if (cookieValue) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    console.log("Cookie deleted:", cookieName);
  } else {
    console.log("Cookie not found:", cookieName);
  }
}
