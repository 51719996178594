import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, HStack, Icon, Skeleton, Spinner, VStack, useDisclosure } from "@chakra-ui/react";
import { PiTiktokLogo } from "react-icons/pi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getInfluencerProfileDetails, togglePinForItinerary, regenerateItinerary } from "../services/InfluencerService";
import TiktokItineraryCard from "../features/Tiktok/TiktokItineraryCard";
import { useMediaQuery } from "react-responsive";
import ShareProfile from "../modals/Influencer/ShareProfile";
import BurgerMenu from "../features/BurgerMenu/BurgerMenu";
import { useAuth } from "../hooks/useAuth";
import { getCookie } from "../utils";
import { getActivityLink } from "../utils/itinerary";

export default function InfluencerProfile() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [influencer, setInfluencer] = useState(undefined);
  const [tiktokItineraries, setTiktokItineraries] = useState([]);
  const [isAllItinerariesProcessed, setIsAllItinerariesProcessed] = useState(true);
  const [featuredItineraryCards, setFeaturedItineraryCards] = useState([]);
  // if itineraryId is present in array meaning it is currently being toggled.
  const [togglePinsLoading, setTogglePinsLoading] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // getting the username from the url.
  const { username } = useParams();
  const navigate = useNavigate();
  const { influencer: influencerFromAuth } = useAuth();

  const isUserOwner = useMemo(() => {
    return influencerFromAuth?.username === username || influencerFromAuth?.username === "itair.io" || process.env.NODE_ENV === "development";
  }, [influencerFromAuth?.username, username]);

  useEffect(() => {
    if (influencerFromAuth?.username === username ) {
      window.heap && window.heap.identify(username);
      window.heap && window.heap.addUserProperties({
        numberOfVideos: tiktokItineraries.length,
      });
    }
  }, [tiktokItineraries.length, username, influencerFromAuth?.username]);

  const handleTogglePin = async (itineraryId) => {
    try {
      setTogglePinsLoading([...togglePinsLoading, itineraryId]);
      const influencerId = getCookie("influencerId");

      if (!influencerId) {
        console.error("No influencerId found in cookies.");
        setTogglePinsLoading(togglePinsLoading.filter((id) => id !== itineraryId));
        return;
      }

      const isPinned = influencer?.pins?.includes(itineraryId);
      await togglePinForItinerary(influencerId, itineraryId, !isPinned);
      await refetchAndUpdateItineraries();
      setTogglePinsLoading(togglePinsLoading.filter((id) => id !== itineraryId));
    } catch (error) {
      console.log("Error pinning itinerary: ", error);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getInfluencerProfileDetails(username);
      console.log("Influencer profile data: ", data);
      setInfluencer(data);
      setFeaturedItineraryCards(data?.featuredItineraryCards || []);
      const sortedIntineraries = data?.itineraries.sort((a, b) => b.createdAt - a.createdAt);
      const pinnedItineraries = data?.pins.map((pin) => sortedIntineraries.find((itinerary) => itinerary._id === pin));
      const unpinnedItineraries = sortedIntineraries.filter((itinerary) => !data?.pins.includes(itinerary._id));
      const mergedItineraries = [...pinnedItineraries, ...unpinnedItineraries];
      const _isAllItinerariesProcessed = mergedItineraries.every((itinerary) => itinerary.title);
      setIsAllItinerariesProcessed(_isAllItinerariesProcessed);
      setTiktokItineraries(mergedItineraries || []);
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message);
      navigate("/", { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  const refetchAndUpdateItineraries = async () => {
    try {
      setIsRefetching(true);
      const { data } = await getInfluencerProfileDetails(username);
      setInfluencer(data);
      console.log("refetched Itineraries", data?.itineraries);
      const updatedItineraries = getMergedItineraries(data?.itineraries);

      const sortedIntineraries = updatedItineraries.sort((a, b) => b.createdAt - a.createdAt);
      const pinnedItineraries = data?.pins.map((pin) => sortedIntineraries.find((itinerary) => itinerary._id === pin));
      const unpinnedItineraries = sortedIntineraries.filter((itinerary) => !data?.pins.includes(itinerary._id));
      const mergedItineraries = [...pinnedItineraries, ...unpinnedItineraries];

      const _isAllItinerariesProcessed = mergedItineraries.every((itinerary) => itinerary?.title);
      setIsAllItinerariesProcessed(_isAllItinerariesProcessed);

      setTiktokItineraries(mergedItineraries);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefetching(false);
    }
  };

  // Replaces the in processing itineraries with their processed versions if they exist.
  const getMergedItineraries = (newItineraries) => {
    const itineraries = [...tiktokItineraries];
    for (let i = 0; i < itineraries.length; i++) {
      if (!itineraries[i].title) {
        let updatedItinerary = newItineraries.find((itinerary) => itinerary._id === itineraries[i]._id);
        if (updatedItinerary && updatedItinerary.title) {
          itineraries[i] = updatedItinerary;
        }
      }
    }
    return itineraries;
  };

  const handleRegenerate = useCallback(
    async (itineraryId) => {
      const { data } = await regenerateItinerary(influencer.tiktokId, itineraryId);
      const updatedItineraries = tiktokItineraries.map((itinerary) =>
        itinerary._id == itineraryId ? data.itinerary : itinerary
      );
      setTiktokItineraries(updatedItineraries);
    },
    [influencer, tiktokItineraries]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAllItinerariesProcessed && !isRefetching) {
        refetchAndUpdateItineraries();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isAllItinerariesProcessed]);

  useEffect(() => {
    if (username) {
      fetchData();
    } else {
      console.error("No username found in the url.");
    }
  }, [username, fetchData]);

  return (
    <div className="influencer-profile">
      <div className="influencer-welcome-body">
        <div className="influencer-profile-header">
          <BurgerMenu />
        </div>
        <div className="profile-details-container">
          <Avatar name={influencer?.displayName} src={influencer?.profileImage} size={"xl"} />
          <VStack minH={24} justifyContent={"space-evenly"} alignItems={"flex-start"} gap={2}>
            <HStack gap={8}>
              {influencer ? <h2 className="display-name">{influencer?.displayName}</h2> : <Skeleton w={200} h={10} />}
              {!isTabletOrMobile && (
                <button className="influencer-profile-link-button" onClick={onOpen}>
                  Share Profile Link
                </button>
              )}
            </HStack>
            <HStack sx={{ marginTop: "0px !important" }}>
              <Icon fontSize={"2xl"} border={"1px solid #000"} borderRadius={4}>
                <PiTiktokLogo />
              </Icon>
              <Link to={influencer?.deepLink}>
                <p className="tiktok-profile-link">Tiktok</p>
              </Link>
            </HStack>
            {isTabletOrMobile && (
              <button className="influencer-profile-link-button" onClick={onOpen}>
                Share Profile Link
              </button>
            )}
          </VStack>
        </div>

        <div className="influencer-profile-content-container">
          {featuredItineraryCards.length > 0 && (
            <>
              <div className="featured-itinerary-cards-container">
                {featuredItineraryCards.map((card) => (
                  // featured itinerary card
                  <div className="featured-itinerary-card">
                    <a target="_blank" href={getActivityLink(card)}>
                      <img src={card.image} className="featured-itinerary-card-image" alt="" />
                      <p>{card.title}</p>
                    </a>
                    <p>{card?.location?.name}</p>
                  </div>
                ))}
              </div>
              <hr />
            </>
          )}

          <h2 className="posts-header">My Posts</h2>
          <div className="videos-container">
            {tiktokItineraries.map((tiktokItinerary) => (
              <TiktokItineraryCard
                key={tiktokItinerary._id}
                tiktokItinerary={tiktokItinerary}
                username={username}
                showPin={isUserOwner || influencer?.pins?.includes(tiktokItinerary._id)}
                // Only show the regeneration button if the current user is the owner of the profile.
                showRegeneration={isUserOwner}
                // Only allow editing pins if the current user is the owner of the profile.
                isPinEditable={isUserOwner}
                handlePinClick={handleTogglePin}
                isPinned={influencer?.pins?.includes(tiktokItinerary._id)}
                isPinLoading={togglePinsLoading.includes(tiktokItinerary._id)}
                handleRegenerate={handleRegenerate}
              />
            ))}
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Spinner size={"lg"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <ShareProfile
        isOpen={isOpen}
        onClose={onClose}
        shareUrl={`https://www.itair.io/influencer/${influencer?.username}`}
      />
    </div>
  );
}
