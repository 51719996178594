import React, { useCallback, useEffect, useState } from "react";
import "./InfluencerWelcome.css";
import { useNavigate } from "react-router-dom";
import TiktokSelector from "../features/Tiktok/TiktokSelector";
import { getInfluencerById, getInfluencerVideos, submitVideosForProcessing } from "../services/InfluencerService";
import { Spinner } from "@chakra-ui/react";
import { getCookie } from "../utils";

export default function InfluencerWelcome() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [influencer, setInfluencer] = useState(undefined);
  const [cursor, setCursor] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [tiktoks, setTiktoks] = useState([]);
  const [selectedTiktoks, setSelectedTiktoks] = useState([]);

  const influencerId = getCookie("influencerId");

  const getBiggestTimestamp = (videos) => {
    let biggestTimestamp = 0;
    videos.forEach((video) => {
      if (video.create_time > biggestTimestamp) {
        biggestTimestamp = video.create_time;
      }
    });
    return biggestTimestamp;
  };

  const fetchData = useCallback(
    async (username) => {
      setIsLoading(true);
      try {
        const {
          data: { videos, cursor: _cursor, has_more },
        } = await getInfluencerVideos(influencerId, cursor);

        console.log("videos", videos);
        console.log("tiktoks", tiktoks);

        // if user has no videos, redirect to "/profile".
        // checking both, previous state and result from new call, to avoid redirecting if only one doesn't have videos.
        if (tiktoks.length === 0 && videos.length === 0) {
          console.log("no videos found");
          return navigate(`/influencer/${username}`);
        }
        // sorting videos by create_time in descending order.
        videos.sort((a, b) => b.create_time - a.create_time);

        setTiktoks((prev) => [...prev, ...videos]);
        setCursor(_cursor);
        setHasMore(has_more);
      } catch (error) {
        console.log("error fetching videos", error);
      }

      setIsLoading(false);
    },
    [cursor]
  );

  // Fetch influencer data.
  useEffect(() => {
    // If no influencerId, redirect to home.
    if (!influencerId) {
      return navigate("/influencer.html");
    }
    getInfluencerById(influencerId).then(({ data }) => setInfluencer(data));
  }, []);

  // Fetch videos on scroll.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && influencer?.username) {
          fetchData(influencer?.username);
        }
      },
      { threshold: 1.0 }
    );

    if (document.getElementById("end-of-list")) {
      observer.observe(document.getElementById("end-of-list"));
    }

    return () => {
      if (document.getElementById("end-of-list")) {
        observer.unobserve(document.getElementById("end-of-list"));
      }
    };
  }, [influencer?.username, hasMore, fetchData]);

  const toggleSelection = useCallback(
    (tiktok) => {
      const _selectedTiktoks = [...selectedTiktoks];
      const index = _selectedTiktoks.findIndex((selectedTiktok) => selectedTiktok.id === tiktok.id);
      if (index < 0) setSelectedTiktoks((prev) => [...prev, tiktok]);
      else {
        _selectedTiktoks.splice(index, 1);
        setSelectedTiktoks(_selectedTiktoks);
      }
    },
    [selectedTiktoks]
  );

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const timestamp = getBiggestTimestamp(tiktoks);
      const { data, status } = await submitVideosForProcessing(influencerId, selectedTiktoks, timestamp);
      console.log("successfully submitted videos for processing", data);
      if (status === 200) {
        navigate(`/influencer/${influencer.username}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [tiktoks, selectedTiktoks, influencer, isSubmitting]);

  return (
    <div className="influencer-welcome">
      <div className="influencer-welcome-header">
        <a href="#" className="brand">
          <img
            src="../images/Itair-Logo-1.png"
            loading="lazy"
            sizes="(max-width: 479px) 110px, 150px"
            srcSet="../images/Itair-Logo-1-p-500.png 500w, ../images/Itair-Logo-1-p-800.png 800w, ../images/Itair-Logo-1.png 1076w"
            alt=""
            className="image"
            onClick={(e) => navigate("/")}
          />
        </a>
      </div>
      <div className="influencer-welcome-body">
        <h1 className="heading">Welcome @{influencer?.username}!</h1>
        <p className="description">Choose your Tiktok videos you'd like to make money on.</p>
        <p className="description">Make sure they are travel videos.</p>
        <div id="tiktok-videos-container" className="videos-container">
          {tiktoks.map((tiktok) => (
            <TiktokSelector
              key={tiktok.id}
              tiktok={tiktok}
              isSelected={selectedTiktoks.findIndex((selectedTiktok) => selectedTiktok.id === tiktok.id) > -1}
              toggleSelection={toggleSelection}
            />
          ))}
          {isLoading && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Spinner size={"lg"} />
            </div>
          )}
          <div id="end-of-list"></div>
        </div>
        <div className="influencer-sticky-action-button-container">
          <button className={"submit"} onClick={handleSubmit}>
            {isSubmitting ? <Spinner /> : "SUBMIT"}
          </button>
        </div>
      </div>
    </div>
  );
}

