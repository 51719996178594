import React from "react";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Button,
  Link,
  HStack,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { convertSubcategoryToMainCategory, truncateString } from "../../utils";
import LodgingBookingLinkIcon from "../../shared-components/LodgingBookingLinkIcon";

const BookingLinksModal = ({ isOpen, onClose, onOpen, activityDetails, trackLinkClick }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const mainCategory = convertSubcategoryToMainCategory(activityDetails?.category);

  return (
    <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={"xl"}>
      <ModalOverlay />
      <ModalContent
        py={"1rem"}
        pb={"2.5rem"}
        px={isTabletOrMobile ? "1.5rem" : "2rem"}
        borderRadius={"none"}
        border="3px solid black"
        backgroundColor={"base.100"}
      >
        <ModalCloseButton position="absolute" left={2} color={"black"}></ModalCloseButton>
        <VStack spacing={"1rem"} width={isTabletOrMobile ? "100%" : "85%"} mx={"auto"}>
          <ModalHeader textAlign={"center"} p={0}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              Booking Links
            </Text>
          </ModalHeader>
          <VStack
            spacing={"1rem"}
            alignItems={"start"}
            minH={isTabletOrMobile ? "20rem" : "15rem"}
            maxHeight={isTabletOrMobile ? "20rem" : "15rem"}
            overflowY={"auto"}
            justifyContent={"start"}
            w={"full"}
            pr={isTabletOrMobile ? "0.5rem" : "1rem"}
            py={"1rem"}
          >
            {activityDetails?.bookingLinks?.length > 0 &&
              activityDetails?.bookingLinks.map((linkObj) => linkObj.link ? (
                <HStack
                  width={"full"}
                  height={"2rem"}
                  spacing={"2rem"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <HStack>
                    <LodgingBookingLinkIcon name={linkObj.source} width="2rem" />
                    <Link onClick={() => {trackLinkClick(mainCategory, linkObj.title, "BookingDetailsLinkClicked");}} href={linkObj.link} isExternal>
                      <Text fontWeight={"medium"} fontSize={isTabletOrMobile ? "smaller" : "md"} lineHeight={"shorter"}>
                        {mainCategory === "other" ? truncateString(linkObj.title, 65) : linkObj.source}
                      </Text>
                    </Link>
                  </HStack>

                  <Button
                    minW={isTabletOrMobile ? "3.5rem" : "4rem"}
                    maxW={isTabletOrMobile ? "3.5rem" : "4rem"}
                    onClick={() => {
                      trackLinkClick(mainCategory, linkObj.title, "BookingDetailsLinkClicked");
                      window.open(linkObj.link, "_blank")}}
                  >
                    {linkObj.price ? `${linkObj.price}` : "View"}
                  </Button>
                </HStack>
              ) : null)}
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default BookingLinksModal;
