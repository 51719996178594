import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Button,
} from "@chakra-ui/react";
import React from "react";
import LoggedIn from "./LoggedIn";
import { useMediaQuery } from "react-responsive";
import { FaUserAlt } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { useLoginModal } from "../../hooks/useLoginModal";
import { useAuth } from "../../hooks/useAuth";
import { loginWithTiktok } from "../../services/InfluencerService";

export const Login = (props) => {
  const { isOpen, onOpen, onClose } = useLoginModal();
  const { influencer } = useAuth();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleLoginWithTiktok = async () => {
    try {
      const { data } = await loginWithTiktok();
      const url = data.tiktokAuthUrl;

      // close the login modal
      onClose();
      // redirect to tiktok login page
      window.open(url, "_self");
    } catch (error) {
      console.log("error logging in with tiktok", error);
    }
  };

  // props.display will be none when we are trying to login from a share page
  // TODO: fix this hacky method of forcing a login prompt for a user even if influencer account is logged in on share pages by using props.display
  return influencer?.username && props.display !== "none" ? (
    // Show when an existing user is logged in.
    <LoggedIn display={props.display}></LoggedIn>
  ) : (
    <>
      {/* show icon if on mobile otherwise show login textual button. */}
      {isTabletOrMobile ? (
        <Box
          id={"mobile-login-button"}
          borderRadius={"100%"}
          display={props.display ? props.display : "flex"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={"#f39d20"}
          h={"3rem"}
          w={"3rem"}
          onClick={onOpen}
        >
          <FaUserAlt fontSize={"1.5rem"} color="white"></FaUserAlt>
        </Box>
      ) : (
        <Box display={props.display ? props.display : "auto"}>
          <button id="login-button" onClick={onOpen} className="card-detail-modal-header-publish-button">
            <b className="card-detail-modal-header-publish-button-text">{"LOGIN"}</b>
          </button>
        </Box>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent border="3px solid black" backgroundColor={"base.100"} paddingBottom={"1rem"} minHeight={"300px"}>
          <ModalCloseButton></ModalCloseButton>

          <VStack spacing={"1rem"}>
            <ModalHeader className="card-detail-modal-header-title-text" pb={0}>
              Sign up or Log in
            </ModalHeader>

            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={"150px"}>
              <Button
                onClick={handleLoginWithTiktok}
                bg="black"
                fontWeight={"medium"}
                fontSize={"0.8rem"}
                minWidth={"240px"}
                position={"relative"}
              >
                <FaTiktok color="white" size={"1.25rem"} style={{ position: "absolute", left: "1rem" }} />
                Sign in with Tiktok
              </Button>
            </Box>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Login;

