import {
  Box,
  Container,
  HStack,
  Image,
  Text,
  Button,
  VStack,
  Spinner,
  Avatar,
  Heading,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Logo from "./../../assets/brand/logo_big.png";
import DailyItineraryContainer from "./DailyItineraryContainer";
import { useDispatch, useSelector } from "react-redux";
import { getInfluencerItineraryById } from "../../reducers/stateSlice";
import { IoIosShareAlt } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import ShareCreatorItinerary from "../../modals/ShareCreatorItinerary/ShareCreatorItinerary";
import BookingLinksModal from "../../modals/BookingLinksModal/BookingLinksModal";
import { getActivityCategory } from "../../utils/itinerary";

const CreatorSharedItinerary = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { username, id } = useParams();
  const navigate = useNavigate();

  const {
    isOpen: isBookingLinksModalOpen,
    onOpen: onBookingLinksModalOpen,
    onClose: onBookingLinksModalClose,
  } = useDisclosure();

  const [selectedActivityForBookingModal, setSelectedActivityForBookingModal] = useState(null);

  const trackLinkClick = (category, name, source) => {
    window.heap && window.heap.track(source, {
      username: username,
      itineraryId: id,
      type: category,
      name: name,
    });
  }

  const handleOpenBookingLinksModal = (activitydetails) => {
    let category = getActivityCategory(activitydetails);

    window.heap && window.heap.track("ViewDetailsClicked", {
      username: username,
      itineraryid: id,
      type: category,
      name: activitydetails?.title,
    });

    const bookinglinks = activitydetails?.bookingLinks || [];
    let linktoopen;

    if (category === 'lodging') {
      linktoopen = bookinglinks.find(link => link.source.toLowerCase() === 'expedia.com')?.link ||
                   bookinglinks.find(link => link.source.toLowerCase() === 'hotels.com')?.link ||
                   bookinglinks.find(link => link.source.toLowerCase() === 'booking.com')?.link ||
                   bookinglinks.find(link => link.source.toLowerCase() === 'trip.com')?.link ||
                   bookinglinks.find(link => link.link)?.link;
    } else {
      linktoopen = bookinglinks.find(link => link.source.toLowerCase() === 'viator')?.link ||
                   bookinglinks.find(link => link.source.toLowerCase() === 'getyourguide')?.link ||
                   bookinglinks.find(link => link.link)?.link;
    }

    console.log("linktoopen", linktoopen, activitydetails);

    if (linktoopen) {
      window.open(linktoopen, '_blank');
    }
  };

  const { isLoading, data: itineraryData, error } = useSelector((state) => state.main.getInfluencerItineraryById);
  const dispatch = useDispatch();
  const iframeRef = useRef(null);
  const [showIframe, setShowIframe] = useState(false);

  const { isOpen, onOpen: onShareModalOpen, onClose } = useDisclosure();

  const [locationObj, setLocationObj] = useState({});
  const defaultLocation = locationObj && locationObj.formatted_address;

  const [regionText, setRegionText] = useState("");

  useEffect(() => {
    if (itineraryData && itineraryData.data.location) {
      setLocationObj(itineraryData.data.location);
    }
  }, [itineraryData]);

  useEffect(() => {
    if (!regionText) {
      setRegionText(defaultLocation);
    }
  }, [defaultLocation]);

  useEffect(() => {
    if ((username, id)) {
      window.heap && window.heap.track("CreatorVideoViewed", {
        username: username,
        itineraryId: id,
      });
      dispatch(
        getInfluencerItineraryById({
          username,
          itineraryId: id,
        })
      );
    }
  }, [username, id]);

  const reloadIframe = () => {
    setShowIframe(true);
    const currentSrc = iframeRef.current.src;
    iframeRef.current.src = ""; // Reset src to force reload
    iframeRef.current.src = currentSrc; // Set it back
  };

  return (
    <Box
      display={"flex"}
      flexDirection={isTabletOrMobile ? "column" : "row"}
      justifyContent={isTabletOrMobile ? undefined : "center"}
      height={"100%"}
      overflow={isTabletOrMobile ? "auto" : undefined}
    >
      <BookingLinksModal
        activityDetails={selectedActivityForBookingModal}
        onClose={onBookingLinksModalClose}
        onOpen={onBookingLinksModalOpen}
        isOpen={isBookingLinksModalOpen}
        trackLinkClick={trackLinkClick}
      ></BookingLinksModal>
      <Box
        display={"flex"}
        flexDirection={isTabletOrMobile ? "column" : "row"}
        justifyContent={isTabletOrMobile ? undefined : "center"}
        w={"100%"}
      >
        <VStack justifyContent={"center"} height={"100%"} width={isTabletOrMobile ? "100%" : "35%"}>
          <VStack width={"100%"} maxWidth={"360px"} py={"2rem"} px={2}>
            <HStack width={"100%"}>
              <Avatar size={"sm"} src={itineraryData?.data?.profileImage} />
              <Heading fontSize={"1.5rem"} fontWeight={700} w={"100%"}>
                {itineraryData?.data?.displayName || username}
              </Heading>
              <button
                className="influencer-profile-link-button"
                onClick={() => {
                  navigate(`/influencer/${username}`);
                }}
              >
                Profile
              </button>
            </HStack>
            <Box
              width={isTabletOrMobile ? "35%" : "100%"}
              maxW={isTabletOrMobile ? "200px" : "100%"}
              position={isTabletOrMobile ? "absolute" : "relative"}
              bottom={isTabletOrMobile ? 4 : undefined}
              left={isTabletOrMobile ? 4 : undefined}
              zIndex={isTabletOrMobile ? 1 : undefined}
            >
              <video
                style={{
                  width: "100%",
                  aspectRatio: "9 / 16",
                }}
                src={itineraryData?.data?.cloudinaryUrl}
                controls
                loop
                autoPlay
                muted
                playsInline
              />
            </Box>
            {!isTabletOrMobile && (
              <Button
                display={"flex"}
                alignItems={"center"}
                gap={2}
                height={"2rem"}
                px={"0.5rem"}
                ml={"auto !important"}
                minWidth={"4rem"}
                bgColor={"#e8fbef"}
                color={"black"}
                border={"2px solid #000"}
                borderRadius={"0.3rem"}
                cursor={"pointer"}
                fontWeight={600}
                onClick={() => {
                  window.heap && window.heap.track("Clicked Shared Button");
                  onShareModalOpen();
                }}
              >
                <Icon color={"black"} boxSize={7} as={IoIosShareAlt}></Icon>
                <span className="itinerary-manipulator-prompt-bar-regenerate-button-text">Share</span>
              </Button>
            )}
          </VStack>
        </VStack>
        {isLoading ? (
          <Container maxW="container.lg" centerContent p={0}>
            <Box
              width={"100%"}
              minH={"100%"}
              border="2px"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Spinner size="xl" />
            </Box>
          </Container>
        ) : itineraryData?.data?.isItineraryProcessing ? (
          <Container
            maxW="container.lg"
            centerContent
            p={0}
            position={"relative"}
            minW={isTabletOrMobile ? undefined : "65%"}
            overflow={isTabletOrMobile ? undefined : "auto"}
          >
            <Box
              width={"100%"}
              minH={"100%"}
              border="2px"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Heading>Current Itinerary is in process...</Heading>
            </Box>
          </Container>
        ) : (
          <Container
            maxW="container.lg"
            centerContent
            p={0}
            position={"relative"}
            minW={isTabletOrMobile ? undefined : "65%"}
            overflow={isTabletOrMobile ? undefined : "auto"}
          >
            {/* Header Box */}
            <Box
              flex="1"
              px={6}
              pt={2}
              pb={8}
              zIndex={0}
              minW="100%"
              border={isTabletOrMobile ? "0px" : "2px"}
              borderBottom={"none"}
            >
              {!isTabletOrMobile && (
                <Box width={"fit-content"} ml={"auto"}>
                  <Link to="/home">
                    <Image
                      top={4}
                      right={!isTabletOrMobile ? 6 : undefined}
                      left={!isTabletOrMobile ? undefined : 4}
                      maxH={50}
                      objectFit="contain"
                      src={Logo}
                      alt="Itair logo"
                    />
                  </Link>
                </Box>
              )}
              <Text
                fontFamily={"Rubik, sans-serif"}
                fontSize={isTabletOrMobile ? "4xl" : "5xl"}
                fontWeight={"bold"}
                lineHeight={"shorter"}
                p={0}
                textAlign={isTabletOrMobile ? "center" : "left"}
              >
                {itineraryData?.data?.title}
              </Text>
            </Box>
            <VStack borderX={"2px"} paddingTop={isTabletOrMobile ? undefined : 2} w={"100%"} pb="2rem">
              {itineraryData?.data?.days &&
                itineraryData?.data.days.map((items, index) => (
                  <DailyItineraryContainer
                    showDetailButtons={true}
                    handleViewPrices={handleOpenBookingLinksModal}
                    key={index}
                    title={`Day ${index + 1}`}
                    items={items}
                    trackLinkClick={trackLinkClick}
                  />
                ))}
            </VStack>
          </Container>
        )}
      </Box>
      <ShareCreatorItinerary isOpen={isOpen} onClose={onClose} shareUrl={window.location.href} />
    </Box>
  );
};

export default CreatorSharedItinerary;
