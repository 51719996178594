import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import Layout from "./Layout";
import Login from "./pages/Login";
import PublicSharedItinerary from "./pages/SharedItinerary/PublicSharedItinerary";
import InfluencerWelcome from "./pages/InfluencerWelcome";
import InfluencerProfile from "./pages/InfluencerProfile";
import Dashboard from "./pages/Dashboard";
import CreatorSharedItinerary from "./pages/SharedItinerary/CreatorSharedItinerary";
import InfluencerManageVideos from "./pages/InfluencerManageVideos";
import ClaimProfile from "./pages/ClaimProfile";
import AccountDeleteSuccessful from "./pages/AccountDeleteSuccessful";
import LoginError from "./pages/LoginError";

function App() {
  const RedirectSite = () => {
    window.location.href = "/influencer.html";
    return <></>;
  };

  const RedirectTOS = () => {
    window.location.href = "/tos.html";
    return <></>;
  };

  const RedirectPrivacy = () => {
    window.location.href = "/privacy.html";
    return <></>;
  };
  const RedirectInfluencer = () => {
    window.location.href = "/influencer.html";
    return <></>;
  };

  return (
    <Box h={"100%"} bgColor={"base.100"}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RedirectSite />} />
          <Route path="/tos" element={<RedirectTOS />} />
          <Route path="/privacy" element={<RedirectPrivacy />} />
          <Route path="/influencer" element={<RedirectInfluencer />} />
          <Route path="/influencer/welcome" element={<InfluencerWelcome />} />
          <Route path="/influencer/manage-videos" element={<InfluencerManageVideos />} />
          <Route path="/influencer/:username" element={<InfluencerProfile />} />
          <Route path="/influencer/claim-profile/:username" element={<ClaimProfile />} />
          <Route path="/influencer/account-delete-success" element={<AccountDeleteSuccessful />} />
          <Route path="/influencer/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/login-error" element={<LoginError />}></Route>
          <Route path="/itinerary/public/:tag/:url" element={<PublicSharedItinerary />} />
          <Route path="/:username/:id" element={<CreatorSharedItinerary />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
export default App;

