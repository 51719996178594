export const colors = {
  // The yellow brown bg.
  base: {
    100: "#FFF4DE",
    200: "#FFF4DE",
    300: "#FFF4DE",
    400: "#FFF4DE",
    500: "#FFF4DE",
    600: "#FFF4DE",
  },
  // The greenish one.
  brand: {
    100: "#18d49f",
    200: "#18d49f",
    300: "#18d49f",
    400: "#18d49f",
    500: "#18d49f",
    600: "#18d49f",
  },
  // Greyish
  secondaryBrand: {
    100: "#606060",
    200: "#606060",
    300: "#606060",
    400: "#606060",
    500: "#606060",
    600: "#606060",
  },
  purple: {
    100: "#7540ef",
    200: "#7540ef",
    300: "#7540ef",
    400: "#7540ef",
    500: "#7540ef",
  },
  customBlack: {
    100: "#232323",
    200: "#232323",
    300: "#232323",
    400: "#232323",
    500: "#232323",
  },
  customOrange: {
    100: "#f39d20",
  },
};
