import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./InfluencerWelcome.css";
import { useNavigate } from "react-router-dom";
import TiktokSelector from "../features/Tiktok/TiktokSelector";
import {
  getInfluencerById,
  getInfluencerPopulatedVideos,
  updateVideosForProcessing,
  updateInfluencerAIPreference,
} from "../services/InfluencerService";
import { Box, Text, Icon, Spinner, Tooltip, Checkbox, Flex } from "@chakra-ui/react";
import { getCookie } from "../utils";
import { MdPushPin } from "react-icons/md";
import BurgerMenu from "../features/BurgerMenu/BurgerMenu";

export default function InfluencerManageVideos() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [influencer, setInfluencer] = useState(undefined);
  const [cursor, setCursor] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [tiktoks, setTiktoks] = useState([]);
  const [allowAI, setAllowAI] = useState(false);
  const isSelected = useMemo(() => tiktoks.filter((tiktok) => tiktok.isSelected).length > 0, [tiktoks]);

  const influencerId = getCookie("influencerId");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        data: { videos, cursor: _cursor, has_more },
      } = await getInfluencerPopulatedVideos(influencerId, cursor);

      // sorting videos by create_time in descending order.
      videos.sort((a, b) => b.create_time - a.create_time);

      setTiktoks((prev) => [...prev, ...videos]);
      setCursor(_cursor);
      setHasMore(has_more);
    } catch (error) {
      console.log("error fetching videos", error);
    }

    setIsLoading(false);
  }, [cursor]);

  // Fetch influencer data.
  useEffect(() => {
    // If no influencerId, redirect to home.
    if (!influencerId) {
      return navigate("/");
    }

    if (influencerId) {
      getInfluencerById(influencerId).then(({ data }) => {
        setInfluencer(data);
        setAllowAI(data.allowAIVideoSelection || false);
      });
    }
  }, [influencerId]);

  // Fetch videos on scroll.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && influencer?.username) {
          fetchData(influencer?.username);
        }
      },
      { threshold: 1.0 }
    );

    if (document.getElementById("end-of-list")) {
      observer.observe(document.getElementById("end-of-list"));
    }

    return () => {
      if (document.getElementById("end-of-list")) {
        observer.unobserve(document.getElementById("end-of-list"));
      }
    };
  }, [influencer?.username, hasMore, fetchData]);

  const toggleSelection = useCallback(
    (tiktok) => {
      const temp = [...tiktoks];
      const index = temp.findIndex((_tiktok) => _tiktok.id === tiktok.id);
      temp[index].isSelected = !temp[index].isSelected;
      setTiktoks(temp);
    },
    [tiktoks]
  );

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const { data, status } = await updateVideosForProcessing(influencerId, tiktoks);
      await updateInfluencerAIPreference(influencerId, allowAI);
      console.log("successfully submitted videos for processing", data);
      if (status === 200) {
        navigate(`/influencer/${influencer.username}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [tiktoks, influencer, isSubmitting, allowAI]);

  return (
    <div className="influencer-welcome">
      <div className="influencer-profile-header">
        <BurgerMenu />
      </div>
      <div className="influencer-welcome-header">
        <a href="#" className="brand">
          <img
            src="../images/Itair-Logo-1.png"
            loading="lazy"
            sizes="(max-width: 479px) 110px, 150px"
            srcSet="../images/Itair-Logo-1-p-500.png 500w, ../images/Itair-Logo-1-p-800.png 800w, ../images/Itair-Logo-1.png 1076w"
            alt=""
            className="image"
            onClick={(e) => navigate("/")}
          />
        </a>
      </div>
      <div className="influencer-welcome-body">
        <h1 className="heading">Manage Videos</h1>
        <p className="description">Add or remove the videos you'd like to monetize.</p>
        <p className="description">Make sure they are travel videos.</p>
        <Flex justifyContent="flex-end" alignItems="center" mb={4}>
          <Checkbox
            isChecked={allowAI}
            onChange={(e) => setAllowAI(e.target.checked)}
            size="lg"
            colorScheme="blue"
            sx={{
              "& .chakra-checkbox__control": {
                bg: allowAI ? "black" : "initial",
                borderColor: allowAI ? "black" : "black",
                _hover: {
                  bg: allowAI ? "black" : "initial",
                  borderColor: allowAI ? "black" : "black",
                },
              },
            }}
          ></Checkbox>

          <Text as={"b"} ml={2}>
            Allow AI to choose videos for you in the future?
          </Text>
        </Flex>
        <div id="tiktok-videos-container" className="videos-container">
          {tiktoks.map((tiktok) => (
            <TiktokSelector
              key={tiktok.id}
              tiktok={tiktok}
              isSelected={tiktok.isSelected}
              toggleSelection={toggleSelection}
            />
          ))}
          {isLoading && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Spinner size={"lg"} />
            </div>
          )}
          <div id="end-of-list"></div>
        </div>
        <div className="influencer-sticky-action-button-container">
          <button className={"submit"} onClick={handleSubmit}>
            {isSubmitting ? <Spinner /> : "SUBMIT"}
          </button>
        </div>
      </div>
    </div>
  );
}

