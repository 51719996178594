import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Button, HStack, Icon, Skeleton, Spinner, VStack, useDisclosure } from "@chakra-ui/react";
import { PiTiktokLogo } from "react-icons/pi";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getInfluencerClaimProfileDetails,
  deleteInfuencerClaimProfile,
  loginWithTiktok,
} from "../services/InfluencerService";
import TiktokItineraryCard from "../features/Tiktok/TiktokItineraryCard";
import { useMediaQuery } from "react-responsive";
import ShareProfile from "../modals/Influencer/ShareProfile";
import BurgerMenu from "../features/BurgerMenu/BurgerMenu";

export default function ClaimProfile() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const [influencer, setInfluencer] = useState(undefined);
  const [tiktokItineraries, setTiktokItineraries] = useState([]);
  const [isAllItinerariesProcessed, setIsAllItinerariesProcessed] = useState(true);
  // if itineraryId is present in array meaning it is currently being toggled.
  const { isOpen, onOpen, onClose } = useDisclosure();
  // getting the username from the url.
  const { username } = useParams();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getInfluencerClaimProfileDetails(username);
      console.log("Influencer profile data: ", data);
      setInfluencer(data);
      const sortedIntineraries = data?.itineraries.sort((a, b) => b.createdAt - a.createdAt);
      const pinnedItineraries = data?.pins.map((pin) => sortedIntineraries.find((itinerary) => itinerary._id === pin));
      const unpinnedItineraries = sortedIntineraries.filter((itinerary) => !data?.pins.includes(itinerary._id));
      const mergedItineraries = [...pinnedItineraries, ...unpinnedItineraries];
      const _isAllItinerariesProcessed = mergedItineraries.every((itinerary) => itinerary.title);
      setIsAllItinerariesProcessed(_isAllItinerariesProcessed);
      setTiktokItineraries(mergedItineraries || []);
    } catch (error) {
      console.log(error);
      alert(error?.response?.data?.message);
      navigate("/", { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  const refetchAndUpdateItineraries = async () => {
    try {
      setIsRefetching(true);
      const { data } = await getInfluencerClaimProfileDetails(username);
      setInfluencer(data);
      console.log("refetched Itineraries", data?.itineraries);
      const updatedItineraries = getMergedItineraries(data?.itineraries);

      const sortedIntineraries = updatedItineraries.sort((a, b) => b.createdAt - a.createdAt);
      const pinnedItineraries = data?.pins.map((pin) => sortedIntineraries.find((itinerary) => itinerary._id === pin));
      const unpinnedItineraries = sortedIntineraries.filter((itinerary) => !data?.pins.includes(itinerary._id));
      const mergedItineraries = [...pinnedItineraries, ...unpinnedItineraries];

      const _isAllItinerariesProcessed = mergedItineraries.every((itinerary) => itinerary?.title);
      setIsAllItinerariesProcessed(_isAllItinerariesProcessed);

      setTiktokItineraries(mergedItineraries);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefetching(false);
    }
  };

  // Replaces the in processing itineraries with their processed versions if they exist.
  const getMergedItineraries = (newItineraries) => {
    const itineraries = [...tiktokItineraries];
    for (let i = 0; i < itineraries.length; i++) {
      if (!itineraries[i].title) {
        let updatedItinerary = newItineraries.find((itinerary) => itinerary._id === itineraries[i]._id);
        if (updatedItinerary && updatedItinerary.title) {
          itineraries[i] = updatedItinerary;
        }
      }
    }
    return itineraries;
  };

  const handleLoginWithTiktok = async () => {
    try {
      const { data } = await loginWithTiktok();
      console.log("data", data);
      const url = data.tiktokAuthUrl;

      // redirect to tiktok login page
      window.open(url, "_self");
    } catch (error) {
      console.log("error logging in with tiktok", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAllItinerariesProcessed && !isRefetching) {
        refetchAndUpdateItineraries();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isAllItinerariesProcessed]);

  useEffect(() => {
    if (username) {
      fetchData();
    } else {
      console.error("No username found in the url.");
    }
  }, [username, fetchData]);

  const handleDeleteProfile = async () => {
    try {
      await deleteInfuencerClaimProfile(username);
      navigate("/influencer/account-delete-success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="influencer-profile">
      <div className="influencer-welcome-body">
        {isTabletOrMobile && (
          <div className="influencer-profile-header">
            <BurgerMenu />
          </div>
        )}
        <div className="profile-details-container">
          <Avatar name={influencer?.displayName} src={influencer?.profileImage} size={"xl"} />
          <VStack minH={24} justifyContent={"space-evenly"} alignItems={"flex-start"} gap={2}>
            <HStack gap={8}>
              {influencer ? <h2 className="display-name">{influencer?.displayName}</h2> : <Skeleton w={200} h={10} />}
              {!isTabletOrMobile && (
                <>
                  <button className="influencer-profile-link-button" onClick={onOpen}>
                    Share Profile Link
                  </button>
                  <Button
                    variant={"primary"}
                    w={160}
                    borderRadius={4}
                    textTransform={"uppercase"}
                    fontSize={"0.9rem"}
                    onClick={handleLoginWithTiktok}
                  >
                    Claim
                  </Button>
                  <Button
                    variant={"error"}
                    w={160}
                    borderRadius={4}
                    textTransform={"uppercase"}
                    fontSize={"0.9rem"}
                    onClick={handleDeleteProfile}
                  >
                    Delete
                  </Button>
                </>
              )}
            </HStack>
            <HStack sx={{ marginTop: "0px !important" }}>
              <Icon fontSize={"2xl"} border={"1px solid #000"} borderRadius={4}>
                <PiTiktokLogo />
              </Icon>
              <Link to={influencer?.deepLink}>
                <p className="tiktok-profile-link">Tiktok</p>
              </Link>
            </HStack>
            {isTabletOrMobile && (
              <button className="influencer-profile-link-button" onClick={onOpen}>
                Share Profile Link
              </button>
            )}
          </VStack>
        </div>
        {isTabletOrMobile && (
          <HStack mt={4}>
            <Button variant={"primary"} w={160} onClick={handleLoginWithTiktok}>
              Claim
            </Button>
            <Button variant={"error"} w={160} onClick={handleDeleteProfile}>
              Delete
            </Button>
          </HStack>
        )}
        <div id="tiktok-videos-container" className="videos-container">
          {tiktokItineraries.map((tiktokItinerary) => (
            <TiktokItineraryCard key={tiktokItinerary._id} tiktokItinerary={tiktokItinerary} username={username} />
          ))}
          {isLoading && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <Spinner size={"lg"} />
            </div>
          )}
        </div>
      </div>
      <ShareProfile
        isOpen={isOpen}
        onClose={onClose}
        shareUrl={`https://www.itair.io/influencer/${influencer?.username}`}
      />
    </div>
  );
}
