import React, { useCallback, useEffect, useState } from "react";
import StatsCard from "../features/Card/StatsCard";
import { getInfluencerStats } from "../services/InfluencerService";
import { Icon, Spinner, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { getCookie } from "../utils";
import { useNavigate } from "react-router-dom";
import { ImCheckmark } from "react-icons/im";
import BurgerMenu from "../features/BurgerMenu/BurgerMenu";
import TransferRequest from "../modals/Dashboard/TransferRequest";
import { useAuth } from "../hooks/useAuth";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState(undefined);
  const influencerId = getCookie("influencerId");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { influencer: influencerFromAuth } = useAuth();
  const username = influencerFromAuth?.username;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { data } = await getInfluencerStats(influencerId);
    setStats(data);
    setIsLoading(false);
  }, [influencerId]);

  useEffect(() => {
    if (!influencerId) {
      return navigate("/")
    }
    fetchData();
  }, [influencerId, fetchData]);

  const handleTransfer = useCallback(() => {
    onOpen();
  }, [onOpen]);

  return (
    <div className="influencer-dashboard">
      <TransferRequest isOpen={isOpen} onClose={onClose} username={username} />
      <div className="influencer-profile-header">
        <BurgerMenu />
      </div>
      <h1 className="heading">Dashboard</h1>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Spinner size={"lg"} />
        </div>
      ) : (
        <>
          <div className="stats-cards-container">
            <StatsCard title="Income" value={`$${stats ? stats.income : 0}`} />
            <StatsCard title="Bookings" value={`${stats ? stats.bookings : 0}`} />
          </div>
          <button className="request-transfer-button" onClick={handleTransfer}>
            REQUEST TRANSFER
          </button>
        </>
      )}
    </div>
  );
}
