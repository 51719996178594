import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import { register } from "swiper/element/bundle";
import { theme } from "./theme";
import { PersistGate } from "redux-persist/integration/react";
import { AbortControllerProvider } from "./hooks/useAbortController";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Register SwiperJS
register();

const stytch = new StytchUIClient("public-token-test-2cbba33e-bdf6-4160-a1a4-f3027d867b7d");

root.render(
  <StytchProvider stytch={stytch}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AbortControllerProvider>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </AbortControllerProvider>
      </PersistGate>
    </Provider>
  </StytchProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

