import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, HStack, VStack, Text } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";

function LoginError() {
  //   redirect to "/login" after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = "/login";
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <VStack w={"100%"} h={"100%"} backgroundColor={"#fff4de"} justifyContent={"space-between"}>
      {/* Nav */}
      <HStack align={"center"} justifyContent={"start"} w={"full"} pl={"2.5rem"} pt={"0.5rem"}>
        <Link to={""}>
          <img
            src="images/Itair-Logo-1.png"
            loading="lazy"
            sizes="(max-width: 479px) 110px, 150px"
            srcSet="images/Itair-Logo-1-p-500.png 500w, images/Itair-Logo-1-p-800.png 800w, images/Itair-Logo-1.png 1076w"
            alt=""
          />
        </Link>
      </HStack>

      <Box mt="-2rem" px={"1.25rem"}>
        <Text fontSize={isMobile ? "1.45rem" : "2rem"} fontWeight={"bold"} textAlign={"center"} fontFamily={"heading"}>
          There was an error logging you in.
          <br />
          Please try again.
        </Text>

        <Text fontSize={"1rem"} fontWeight={"semibold"} textAlign={"center"} pt={"2rem"} fontFamily={"heading"}>
          Automatically redirecting you to the login page in a few seconds.
        </Text>
      </Box>

      <div
        style={{
          gap: "10px",
          paddingBottom: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="/privacy.html">privacy policy</a>
        <a href="/tos.html">terms of service</a>
      </div>
    </VStack>
  );
}

export default LoginError;
